import { getAppMetadata } from '../../metadata/metadata';
import { AudienceFeatureVersion, } from './audience.type';
import { getAudienceFeatureVersion } from '../../metadata/metadata.helper';
import { filterAssetsByAudienceV2 } from './audiencesV2.helper';
import AssetsService from '@/services/assets.service';
function audiencesClassIdFormatted(audiencesRaw) {
    const audiences = [];
    let audienceGroup = [];
    audiencesRaw.forEach((audienceGroupRaw) => {
        audienceGroupRaw.audiences.forEach((audienceRaw) => {
            const id = audienceRaw.ids[0];
            audienceGroup.push(id);
        });
        audiences.push(audienceGroup);
        audienceGroup = [];
    });
    return audiences;
}
async function filterAssetsByAudienceV1(environment, assets, filter) {
    const filterAudiences = filter.userAudiencesGroup;
    if (filterAudiences === undefined || filterAudiences.length === 0) {
        return { assets, filterApplied: false, assetsAudience: [] };
    }
    const audiences = audiencesClassIdFormatted(filterAudiences);
    const metadata = getAppMetadata();
    if (!(typeof filter.audiencesIndex === 'number')) {
        const msg = `🛑 Audience index ${filter.audiencesIndex} is not a number. Expected number for defining filter values for audiences`;
        // eslint-disable-next-line no-console
        console.error(msg);
        throw new Error(msg);
    }
    const queryParams = {
        audiences: { audiences, index: filter.audiencesIndex },
        delivery: filter.delivery,
    };
    const filteredAssetsId = await AssetsService.getAssetsToShowBasedOnAudienceFilter(metadata, environment, queryParams);
    const mapWithFilteredAssetsId = new Map();
    filteredAssetsId.forEach((assetId) => mapWithFilteredAssetsId.set(assetId, true));
    const filteredAssets = assets.filter((asset) => {
        const hit = mapWithFilteredAssetsId.get(asset.properties.id);
        return hit !== undefined;
    });
    return { assets: filteredAssets, filterApplied: true, assetsAudience: [] };
}
export async function filterAssetsByAudience(environment, assets, filter, latestDelivery) {
    const audienceFeatureVersion = getAudienceFeatureVersion();
    const filterWithDeliveryUpdated = {
        ...filter,
        delivery: filter.delivery || latestDelivery,
    };
    switch (audienceFeatureVersion) {
        case AudienceFeatureVersion.V1: {
            console.debug('           🔍 Audiences [v1]');
            const assetsFiltered = await filterAssetsByAudienceV1(environment, assets, filterWithDeliveryUpdated);
            return assetsFiltered;
        }
        case AudienceFeatureVersion.V2: {
            console.debug('           🔍 Audiences [v2]');
            const assetsFiltered = await filterAssetsByAudienceV2(environment, assets, filterWithDeliveryUpdated);
            return assetsFiltered;
        }
        default: {
            const msg = `🛑 Audience feature version ${audienceFeatureVersion} not supported`;
            // eslint-disable-next-line no-console
            console.error(msg);
            throw new Error(msg);
        }
    }
}
export function propagateAudienceDelivery(filters, deliveryToInclude) {
    let delivery = filters[0].delivery;
    if (deliveryToInclude && !delivery) {
        delivery = deliveryToInclude;
    }
    const filtersUpdated = filters.map((filter) => {
        const filterUpdated = { ...filter };
        filterUpdated.delivery = delivery;
        return filterUpdated;
    });
    return filtersUpdated;
}
export function getAudiencesIdsFromUserAudiencesGroup(userAudiencesGroup) {
    const audiencesIds = [];
    userAudiencesGroup.forEach((audienceGroup) => {
        audienceGroup.audiences.forEach((audience) => {
            audiencesIds.push(audience.ids[0]);
        });
    });
    return audiencesIds;
}
