export const ONE_MILE_TO_METER = 1609.34;
const DISTANCE_EQUATOR_EPSG3857 = 40075016.68; // meters
function metersPerPixel3857(zoomLevel, tileSize = 512) {
    return DISTANCE_EQUATOR_EPSG3857 / (tileSize * Math.pow(2, zoomLevel));
}
export function milesToPixels3857(miles, zoomLevel) {
    if (!miles) {
        return 0;
    }
    return (miles * ONE_MILE_TO_METER) / metersPerPixel3857(zoomLevel);
}
export function milesToMeter(miles) {
    return miles * ONE_MILE_TO_METER;
}
