import layerService from '@/layers/layerService';
import { BOUNDARIES_LAYER, EDITABLE_DATA_LAYER } from '@/layers';
export function manageBoundariesLayerVisibility(legend) {
    // legend props
    const legendBoundariesVisibility = legend.boundariesVisibility ?? true;
    if (legendBoundariesVisibility) {
        layerService.showLayer(BOUNDARIES_LAYER);
        layerService.showLayer(EDITABLE_DATA_LAYER);
    }
    else {
        layerService.hideLayer(BOUNDARIES_LAYER);
        layerService.hideLayer(EDITABLE_DATA_LAYER);
    }
}
