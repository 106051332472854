import { Auth } from '@workspaces/types';
class ResolverBasic {
    userInformation;
    constructor(userInformation) {
        this.userInformation = userInformation;
    }
    getRole() {
        if (this.userInformation.role === Auth.Role.Unknown) {
            throw new Error('Trying to use role unknown when deciding if user can do an action');
        }
        return this.userInformation.role;
        // return Auth.Role.Admin
    }
    isAdmin() {
        return this.getRole() === Auth.Role.Admin;
    }
    isAnonymous() {
        return this.getRole() === Auth.Role.Anonymous;
    }
    getName() {
        return this.userInformation.name;
    }
    isOwnerOrAdmin(user) {
        return this.getName() === user || this.getRole() === Auth.Role.Admin;
    }
}
export default ResolverBasic;
