function getPropertyValueFromObject(object, fields) {
    let value = object;
    for (const field of fields) {
        value = value[field];
    }
    if (typeof value === 'string') {
        return value;
    }
    if (typeof value === 'number') {
        return value;
    }
    throw new Error(`Invalid type for value: ${value}. Expected string or number for path: ${fields} in object: ${object}`);
}
export function findElementInSortedArray(value, fields, data, lowerIndex = 0, higherIndex = data.length - 1) {
    if (data.length === 0) {
        return -1;
    }
    if (higherIndex < lowerIndex) {
        return -1;
    }
    const middleIndex = Math.floor((lowerIndex + higherIndex) / 2);
    const objectPropertyValue = getPropertyValueFromObject(data[middleIndex], fields);
    if (objectPropertyValue === value) {
        return middleIndex;
    }
    if (objectPropertyValue > value) {
        return findElementInSortedArray(value, fields, data, lowerIndex, middleIndex - 1);
    }
    return findElementInSortedArray(value, fields, data, middleIndex + 1, higherIndex);
}
