export var FilePOIColumns;
(function (FilePOIColumns) {
    FilePOIColumns["Id"] = "EXPORT_POI_ID";
    FilePOIColumns["Name"] = "EXPORT_POI_NAME";
    FilePOIColumns["Distance"] = "EXPORT_POI_DISTANCE";
    FilePOIColumns["Address"] = "EXPORT_POI_ADDRESS";
    FilePOIColumns["ZipCode"] = "EXPORT_POI_POSTAL_CODE";
    FilePOIColumns["City"] = "EXPORT_POI_CITY";
})(FilePOIColumns || (FilePOIColumns = {}));
export var POIsPartialQueryAim;
(function (POIsPartialQueryAim) {
    POIsPartialQueryAim["GeoboundariesWithOutGeometry"] = "";
    POIsPartialQueryAim["GeoboundariesWithGeometry"] = "_geoboundaries_with_geometry";
    POIsPartialQueryAim["CustomGeoboundaries"] = "_custom_geoboundaries";
})(POIsPartialQueryAim || (POIsPartialQueryAim = {}));
export var CTEsNameForPOIsGrouping;
(function (CTEsNameForPOIsGrouping) {
    CTEsNameForPOIsGrouping["GeoboundaryWithOutGeometry"] = "all_pois";
    CTEsNameForPOIsGrouping["GeoboundaryWithGeometry"] = "regions_geoboundaries_with_geometry";
    CTEsNameForPOIsGrouping["CustomGeoboundaries"] = "regions_custom_geoboundaries";
    CTEsNameForPOIsGrouping["CombinateGeoboundaries"] = "union_of_all_pois";
})(CTEsNameForPOIsGrouping || (CTEsNameForPOIsGrouping = {}));
