/**
 *  Plan actions module file
 **/

import { ACTIONS, MUTATIONS, GETTERS } from './constants'
import assetsService from '@/api/assetsService'
import layerServiceC3 from '@/layers/layerService'
import {
  ASSETS,
  CLUSTER_ASSETS,
  EXCLUDED_ASSETS,
  EXCLUDED_CLUSTER_ASSETS,
  HEATMAP_ASSETS_LAYER,
} from '@/layers'
import { serializeFilters } from '@/utils/serializeFilters'
import {
  deserializeFilters,
  deserializeLegend,
} from '@/plan/deserializeFilters'
import { MODEL_PLAN, MODEL_SUBPLAN_FILTERS } from './state'
import { GETTERS_PLAN } from '.'
import {
  filterAssetsFromPlan,
  getUnfilteredElements,
  getPlanFiltersFromStorage,
} from '@/helpers/plan.helper'
import PlanService from '@/services/plan.service'
import CustomGeoboundary from '@/services/custom-geoboundary.service'
import { getAppMetadata } from '@/plan/metadata/metadata'
import { managePoisLayerVisibility } from '@/helpers/pois.helper'
import { manageBoundariesLayerVisibility } from '@/helpers/boundaries.helper'
import { manageHeatmapLayerVisibility } from '@/helpers/asset.helper'
import { getPOIsByTypesFromFilter } from '@/plan/filter/pois.helper'
import {
  getZoomLevelForDeclustering,
  getPackageExpireConfirmationTime,
  getPackageExpireCancelTime,
  isAudiencesQuintileEnabled,
  isFeatureLayerHeatMapEnabled,
  isIntelligentMediaTypeDigitalStaticEnabled,
} from '@/plan/metadata/metadata.helper'
import { shouldAssetSimpleLayerBeVisible } from '@/helpers/map.helper'
import { getImpressionsForAssets } from '@/plan/filter/asset.helper'
import sharedPlanService from '@/services/sharedPlan.service'
import BrowserEnvironmentResolver from '@/helpers/environment.helper'
import { serializePlanPackage } from '@/utils/serializePackage'
import { serializeLegend } from '@/utils/serializeLegend'
import { getInstance as getAuth0ServiceIntance } from '@/auth/index'
import { isStateUpdateable } from '@/plan/filter/packages-browser.helper'
import { sendErrorMessageToUser } from '@/helpers/message/message.helper'
import AssetsService from '@/services/assets-browser.service'
import { Package } from '@workspaces/types'
import PackageService from '@/services/packages.service'
import { getPriceValueUpdated } from '@/helpers/package/price.helper'
import {
  getExpirationForAgencyBookingConfirmation,
  getExpirationForAgencyCancellation,
} from '@/plan/filter/packages.helper'
import { sleep } from '@/helpers/common.helper'
import { getAndRemoveNewBaseMapSessionStorage } from '@/utils/sessionStorageUtils'
import { setupAudiencesQuintileLayer } from '@/helpers/audiences-quintile.helper'
import {
  isAudiencesQuintileInFilter,
  isGeoboundaryFilterReadyForAudiencesQuintile,
} from '@/plan/filter/audiences-quintile.helper.ts'
import { analyzeAssetsForAllDigitalOrAllClassic } from '@/helpers/media-type.helper.ts'
import { preparePackageSelection } from '@/helpers/package/package.helper'

const APP_METADATA = getAppMetadata()

export const actions = {
  [ACTIONS.LOAD_PLAN]: async (
    { commit, getters },
    { id, sharedPlan, password },
  ) => {
    commit(MUTATIONS.SET_PLAN_LOADING, true)

    try {
      const defaultPlan = MODEL_PLAN()
      const defaultSubPlanFilters = MODEL_SUBPLAN_FILTERS()
      let plan = null
      if (sharedPlan) {
        console.debug('🟪 Plan SHARED: Fetching...')
        plan = await sharedPlanService.getPlan(
          APP_METADATA,
          BrowserEnvironmentResolver.getInstance(),
          id,
          password,
        )
        plan.created_at = plan.created_at._seconds * 1000
        plan.modified_at = plan.modified_at?._seconds * 1000
      } else {
        console.debug('🟪 Plan: Fetching...')
        plan = await PlanService.getPlan(
          APP_METADATA,
          BrowserEnvironmentResolver.getInstance(),
          id,
        )
      }
      console.debug('🟪 Plan: Preparing filters...')
      const deserializedFilters = deserializeFilters(plan.filters)
      const filters = deserializedFilters.map((subfilter) => {
        return { ...defaultSubPlanFilters, ...subfilter }
      })
      console.debug('🟪 Plan: Preparing attributes...')
      const rawPoisColors = plan.poisColors ?? '{}'
      const poisColors = JSON.parse(rawPoisColors)
      plan.poisColors = poisColors

      const tmpBaseMap = getAndRemoveNewBaseMapSessionStorage()

      let basemap = APP_METADATA.app_config.basemaps.default
      if (plan.basemap) {
        basemap = plan.basemap
      }
      if (tmpBaseMap) {
        basemap = tmpBaseMap
      }

      const preparedPlan = {
        ...defaultPlan,
        ...plan,
        dirty: false,
        filters,
        created: plan.created_at,
        modified: plan.modified_at || plan.created_at,
        legend: deserializeLegend(plan.legend),
        basemap,
      }

      // load dirty plan (going back from insights panel)
      if (!sharedPlan) {
        const { filters, previousRoute } = getPlanFiltersFromStorage()
        if (
          previousRoute &&
          previousRoute.includes('/insights') &&
          previousRoute.includes('/panel') &&
          filters !== null
        ) {
          preparedPlan.filters = filters
          preparedPlan.dirty = true
          console.debug(
            '🟪 Plan: Coming from Insights, reloading old dirty plan',
          )
        } else if (
          filters !== null &&
          getters[GETTERS.IS_TRANSITION_BETWEEN_BASEMAPS]
        ) {
          preparedPlan.filters = filters
          preparedPlan.dirty = true
          commit(MUTATIONS.SET_TRANSITION_BETWEEN_BASEMAPS, false)
          console.debug(
            '🟪 Plan: Transition base map. Reloading old dirty plan',
          )
        }
      }

      console.debug('🟪 Plan: Saving plan in the state...')
      commit(MUTATIONS.SAVE_PLAN, preparedPlan)
      //
      if (sharedPlan) {
        const activeSubPlanFiltersIndex =
          getters[GETTERS_PLAN.GET_SUBPLAN_FILTERS_INDEX]
        const currentSubFilter = filters[activeSubPlanFiltersIndex]
        console.debug('🟪 Plan SHARED: Managing layers visibility...')
        managePoisLayerVisibility(plan.legend, currentSubFilter.proximity)
        manageBoundariesLayerVisibility(plan.legend)
        manageHeatmapLayerVisibility(plan.legend)

        console.debug('🟪 Plan SHARED: Updating proximity data...')
        const { categories, brands, locations, tags } =
          getPOIsByTypesFromFilter(currentSubFilter.proximity.data)
        commit(MUTATIONS.UPDATE_PROXIMITY_DATA, {
          categories,
          brands,
          locations,
          tags,
        })
      }
    } catch (err) {
      // eslint-disable-next-line
      console.error('[actions.js] Error in LOAD_PLAN', err)
      const fromAPI = err.response && err.response.status >= 400
      window.alert(
        `${
          fromAPI ? 'API' : ''
        } Error loading plan. Check the browser console for more details`,
      )
    } finally {
      commit(MUTATIONS.SET_PLAN_LOADING, false)
    }
  },
  [ACTIONS.LOAD_CUSTOM_GEOBOUNDARY]: async ({ commit }, { id }) => {
    commit(MUTATIONS.SET_PLAN_LOADING, true)

    try {
      const defaultPlan = MODEL_PLAN()
      const defaultSubPlanFilters = MODEL_SUBPLAN_FILTERS()
      console.debug('      👉 Fetching custom geoboundary dataset')
      const dataset = await CustomGeoboundary.getCustomGeoboundary(
        APP_METADATA,
        BrowserEnvironmentResolver.getInstance(),
        id,
      )
      console.debug('         OK - Fetching custom geoboundary dataset')
      const deserializedFilters = deserializeFilters(dataset.filters)
      const filters = deserializedFilters.map((subfilter) => {
        return { ...defaultSubPlanFilters, ...subfilter }
      })

      const preparedDataset = {
        ...defaultPlan,
        ...dataset,
        dirty: false,
        filters,
        created: dataset.created_at,
        modified: dataset.modified_at || dataset.created_at,
      }

      commit(MUTATIONS.SAVE_PLAN, preparedDataset)
    } catch (err) {
      // eslint-disable-next-line
      console.error('[actions.js] Error in LOAD_CUSTOM_GEOBOUNDARY', err)
      const fromAPI = err.response && err.response.status >= 400
      window.alert(
        `${
          fromAPI ? 'API' : ''
        } Error loading plan. Check the browser console for more details`,
      )
    } finally {
      commit(MUTATIONS.SET_PLAN_LOADING, false)
    }
  },
  [ACTIONS.CREATE_PLAN]: async ({ commit, state }, payload) => {
    const planFormatted = {
      ...payload,
      filters: JSON.stringify(payload.filters),
      package: serializePlanPackage(payload.package),
      legend: serializeLegend(payload.legend),
    }
    const id = await PlanService.createPlan(
      APP_METADATA,
      BrowserEnvironmentResolver.getInstance(),
      planFormatted,
    )
    const auth0Instance = getAuth0ServiceIntance()
    const userId = auth0Instance.getUser()
    const plan = { ...payload, created_by: userId, id, modified: new Date() }
    commit(MUTATIONS.SAVE_PLAN, {
      ...plan,
      dirty: false,
      filters: state.plan.filters,
    })
    const { categories, brands, locations, tags } = getPOIsByTypesFromFilter(
      state.plan.filters[state.activeSubPlanFiltersIndex].proximity.data,
    )
    commit(MUTATIONS.UPDATE_PROXIMITY_DATA, {
      categories,
      brands,
      locations,
      tags,
    })
    return plan
  },
  [ACTIONS.CREATE_CUSTOM_GEOBOUNDARY]: async ({ commit, state }, payload) => {
    const datasetFormatted = {
      ...payload.dataset,
      filters: JSON.stringify(payload.dataset.filters),
    }
    const userId = getAuth0ServiceIntance().getUser()
    const id = await CustomGeoboundary.createCustomGeoboundary(
      APP_METADATA,
      BrowserEnvironmentResolver.getInstance(),
      userId,
      datasetFormatted,
    )
    const dataset = { ...payload.dataset, id }
    if (!payload.refresh) {
      return dataset
    }
    commit(MUTATIONS.SAVE_PLAN, {
      ...dataset,
      dirty: false,
      filters: state.plan.filters,
    })
    return dataset
  },
  [ACTIONS.UPDATE_PLAN]: async ({ commit, state }, payload) => {
    const plan = {
      ...payload.plan,
      filters: JSON.stringify(payload.plan.filters),
      package: serializePlanPackage(payload.plan.package),
      legend: serializeLegend(payload.plan.legend),
    }

    if (typeof plan.poisColors !== 'string') {
      plan.poisColors = JSON.stringify(plan.poisColors)
    }

    await PlanService.updatePlan(
      APP_METADATA,
      BrowserEnvironmentResolver.getInstance(),
      payload.id,
      plan,
    )
    commit(MUTATIONS.SAVE_PLAN, {
      ...plan,
      modified: new Date(),
      dirty: false,
      filters: state.plan.filters,
      package: state.plan.package,
    })
    const { categories, brands, locations, tags } = getPOIsByTypesFromFilter(
      state.plan.filters[state.activeSubPlanFiltersIndex].proximity.data,
    )
    commit(MUTATIONS.UPDATE_PROXIMITY_DATA, {
      categories,
      brands,
      locations,
      tags,
    })
  },
  [ACTIONS.UPDATE_CUSTOM_GEOBOUNDARY]: async ({ commit, state }, payload) => {
    const dataset = {
      ...payload.plan,
      filters: JSON.stringify(payload.plan.filters),
    }
    await CustomGeoboundary.updateCustomGeoboundary(
      APP_METADATA,
      BrowserEnvironmentResolver.getInstance(),
      payload.id,
      dataset,
    )
    commit(MUTATIONS.SAVE_PLAN, {
      ...dataset,
      dirty: false,
      filters: state.plan.filters,
    })
  },
  [ACTIONS.DELETE_PLAN]: async (context, { id }) => {
    return PlanService.deletePlan(
      APP_METADATA,
      BrowserEnvironmentResolver.getInstance(),
      id,
    )
  },
  [ACTIONS.DELETE_CUSTOM_GEOBOUNDARY]: async (context, { id }) => {
    return CustomGeoboundary.deleteCustomGeoboundary(
      APP_METADATA,
      BrowserEnvironmentResolver.getInstance(),
      id,
    )
  },
  [ACTIONS.LOAD_POIS_LAYER]: async ({ commit }, payload = {}) => {
    try {
      commit(MUTATIONS.SET_MAPUPDATEFLAGS, { pois: false })
    } catch (err) {
      console.error('[actions.js] Error in LOAD_POIS_LAYER', err)
      const fromAPI = err.response && err.response.status >= 400
      window.alert(
        `${
          fromAPI ? 'API' : ''
        } Error updating POIs layer. Check the browser console for more details`,
      )
    }
  },
  [ACTIONS.LOAD_ASSETS]: async (
    { commit, getters },
    params = {
      sql: true,
      meta: true,
      results: true,
      page_size: 30,
      order_by: '-impressions',
    },
    replaceCount = true,
  ) => {
    const assets = await assetsService.getAssets({
      ...params,
      count: undefined,
    })

    if (!replaceCount) {
      const currentCount = getters[GETTERS.GET_ASSETS_COUNT]
      assets.count = currentCount
    }
    commit(MUTATIONS.SET_ASSETS, assets)
    return assets
  },
  [ACTIONS.LOAD_EXCLUDE_ASSETS]: async (
    { commit },
    params = { sql: true, meta: false, results: false },
  ) => {
    const assets = await assetsService.getAssets({
      ...params,
      exclude_action: true,
    })
    commit(MUTATIONS.SET_ASSETS_EXCLUDED, assets)
    return assets
  },
  [ACTIONS.UPDATE_ASSETS_INITIAL_STATS]: async ({ commit }, params = {}) => {
    commit(MUTATIONS.SET_ASSETS_INITIAL_STATS, params)
  },
  [ACTIONS.SET_ASSETS_TOTAL_STATS]: async ({ commit }, params = {}) => {
    commit(MUTATIONS.SET_ASSETS_TOTAL_STATS, params)
  },
  [ACTIONS.UPDATE_ASSET_LAYER]: async ({ commit, getters }, params = {}) => {
    let needToGetSecondaryPropertiesForAssets = false
    const plan = getters[GETTERS_PLAN.GET_PLAN]
    if (params.doNotEmitBusEvent === undefined) {
      params.doNotEmitBusEvent = false
    }

    commit(MUTATIONS.SET_ASSETS_LOADING, true)

    let allAssets = []

    const retiresForWaittingIndexDdInitialization = 5
    const timeBetweenRetries = [0, 100, 200, 300, 400]
    let retries = 0
    let successfullyFetchedAssets = false

    // Getting assets

    while (
      retries < retiresForWaittingIndexDdInitialization &&
      !successfullyFetchedAssets
    ) {
      await sleep(timeBetweenRetries[retries])
      try {
        console.debug(
          `🟪 Plan: 🔁 Fetching Assets: Attempt: ${retries + 1} || Assets: ${
            allAssets.length
          }`,
        )
        if (allAssets.length === 0) {
          const { allGeoJsonAssets, needToGetSecondaryProperties } =
            await AssetsService.fetchLayerV2(
              getAppMetadata(),
              BrowserEnvironmentResolver.getInstance(),
              plan.filters,
            )
          needToGetSecondaryPropertiesForAssets = needToGetSecondaryProperties
          console.debug(
            `🟪 Plan: 🐹 Secondary assets properties needed: ${needToGetSecondaryProperties}`,
          )
          allAssets = allGeoJsonAssets.data.features
          commit(MUTATIONS.SET_ASSETS_READY_WITH_ALL_PROPERTIES, {
            ready: !needToGetSecondaryProperties,
          })
        }
        successfullyFetchedAssets = true
      } catch (err) {
        console.error(
          '   🔴 Vuex - actions.js - UPDATE_ASSET_LAYER - An error occur while trying to fetch assets',
          err,
        )
        retries++
        successfullyFetchedAssets = false
      }
    }
    if (!successfullyFetchedAssets) {
      console.warn(
        '🔴 🔴 Could not set assets into indexBD, seems indexDB is not ready ... 🔴 🔴',
      )
      commit(MUTATIONS.SET_MAPUPDATEFLAGS, { assets: false })
      commit(MUTATIONS.SET_ASSETS_LOADING, false)
      return
    }
    if (retries > 2) {
      console.warn(
        `🟠 Took ${
          retries + 1
        } attempts to correctly fetch assets, it would be a good idea to take a look why`,
      )
    }
    console.debug(
      `🟪 Plan: 🔁 Fetched Assets: Attempt: ${retries + 1} || Assets: ${
        allAssets.length
      }`,
    )

    // To avoid race condition if data for latest delivery is not ready
    let retriesLatestDelivery = 0
    let latestDelivery = getters[GETTERS_PLAN.GET_AUDIENCE_DELIVERY]
    if (!latestDelivery) {
      console.debug(
        `🟪 Plan: 🔁 Fetching audience delivery: Latest: ${latestDelivery}`,
      )
    }
    while (latestDelivery === null && retriesLatestDelivery < 5) {
      await sleep(Math.pow(2, retriesLatestDelivery) * 100)
      latestDelivery = getters[GETTERS_PLAN.GET_AUDIENCE_DELIVERY]
      retriesLatestDelivery++
    }
    if (retriesLatestDelivery > 0) {
      console.debug(
        `🟪 Plan: 🔁 Fetching audience delivery: Attempts: ${retriesLatestDelivery}`,
      )
    }
    console.debug(
      `🟪 Plan: 🔁 Fetched audience delivery: Latest: ${latestDelivery}`,
    )

    const generateAuditEvent =
      params.generateAuditEvent === undefined ? true : params.generateAuditEvent
    console.debug('📢 Audit: Generating Event', generateAuditEvent)
    console.debug('🟪 Plan: 🔍 Filtering assets...')
    let assetsToShow = await filterAssetsFromPlan(
      allAssets,
      plan,
      latestDelivery,
      generateAuditEvent,
    )

    // Update assets count and impressions
    const impressions = getImpressionsForAssets(assetsToShow)
    commit(MUTATIONS.SET_ASSETS_TOTAL_STATS, {
      count: assetsToShow.filter((asset) => !asset.properties.excluded).length,
      impressions,
    })

    const activeSubPlanFiltersIndex =
      getters[GETTERS_PLAN.GET_SUBPLAN_FILTERS_INDEX]
    const currentSubFilter = plan.filters[activeSubPlanFiltersIndex]

    // manage layers visibility
    manageBoundariesLayerVisibility(plan.legend)
    managePoisLayerVisibility(plan.legend, currentSubFilter.proximity)

    // Update proximity data
    const { categories, brands, locations, tags } = getPOIsByTypesFromFilter(
      currentSubFilter.proximity.data,
    )
    commit(MUTATIONS.UPDATE_PROXIMITY_DATA, {
      categories,
      brands,
      locations,
      tags,
    })

    commit(
      MUTATIONS.SET_EXCLUDED_ASSET_LAYER_AVAILABLE,
      assetsToShow.length < allAssets.length,
    )
    const mapZoom = layerServiceC3.getZoom()
    const declusteringZoomLevel = getZoomLevelForDeclustering(APP_METADATA)
    const assetSimpleLayerShouldBeVisible = shouldAssetSimpleLayerBeVisible(
      declusteringZoomLevel,
      mapZoom,
    )
    const assetsLayerVisibility = assetsToShow.length > 0

    const secondaryPropertiesReady =
      getters[GETTERS.GET_ASSETS_READY_WITH_ALL_PROPERTIES]
    console.debug('       All assets properties are ready now? ', {
      needToGetSecondaryPropertiesForAssets,
      secondaryPropertiesReady,
    })
    if (needToGetSecondaryPropertiesForAssets && secondaryPropertiesReady) {
      const { allGeoJsonAssets } = await AssetsService.fetchLayerV2(
        getAppMetadata(),
        BrowserEnvironmentResolver.getInstance(),
        plan.filters,
      )
      allAssets = allGeoJsonAssets.data.features

      const assetsMap = new Map()
      allAssets.forEach((asset) => {
        assetsMap.set(asset.properties.id, asset)
      })
      const assetsToShowFullProperties = assetsToShow.map((asset) => {
        return assetsMap.get(asset.properties.id)
      })
      assetsToShow = assetsToShowFullProperties
      console.debug('        Re-fetched assets properties')
    }

    const isAudiencesQuintileFeatureEnabled =
      isAudiencesQuintileEnabled(APP_METADATA)
    const isAudiencesQuintileEnableInLegend =
      getters[GETTERS_PLAN.GET_AUDIENCES_QUINTILE_VISIBILITY]
    const geoboundaryFiltersAreSetOK =
      isGeoboundaryFilterReadyForAudiencesQuintile(plan.filters[0])
    const audiencesQuintileFiltersAreSetOK = isAudiencesQuintileInFilter(
      plan.filters[0],
    )
    if (
      isAudiencesQuintileFeatureEnabled &&
      isAudiencesQuintileEnableInLegend &&
      geoboundaryFiltersAreSetOK &&
      audiencesQuintileFiltersAreSetOK
    ) {
      setupAudiencesQuintileLayer(
        APP_METADATA,
        BrowserEnvironmentResolver.getInstance(),
        plan.filters[0],
      )
    }

    layerServiceC3.updateLayer(ASSETS, {
      ...params,
      data: allAssets,
      assetsToShow,
      visible: assetsLayerVisibility && assetSimpleLayerShouldBeVisible,
      flyToExtent: true,
      secondaryPropertiesReady,
    })
    layerServiceC3.updateLayer(CLUSTER_ASSETS, {
      ...params,
      data: allAssets,
      assetsToShow,
      visible: assetsLayerVisibility && !assetSimpleLayerShouldBeVisible,
      flyToExtent: true,
      secondaryPropertiesReady,
    })
    if (isFeatureLayerHeatMapEnabled(APP_METADATA)) {
      const heatmapVisibility =
        assetsLayerVisibility &&
        getters[GETTERS_PLAN.GET_HEATMAP_ASSETS_VISIBILITY]
      layerServiceC3.updateLayer(HEATMAP_ASSETS_LAYER, {
        ...params,
        assetsToShow,
        visible: heatmapVisibility,
        heatmapType: getters[GETTERS_PLAN.GET_HEATMAP_ASSETS_TYPE],
      })
    }
    // Legend
    const legend = getters[GETTERS_PLAN.GET_LEGEND_FILTER]
    if (legend.excludeAssets) {
      const assetsExcluded = getUnfilteredElements(allAssets, assetsToShow)
      layerServiceC3.updateLayer(EXCLUDED_CLUSTER_ASSETS, {
        assetsToShow: assetsExcluded,
        ...params,
        visible: !assetSimpleLayerShouldBeVisible,
      })
      layerServiceC3.updateLayer(EXCLUDED_ASSETS, {
        assetsToShow: assetsExcluded,
        ...params,
        visible: assetSimpleLayerShouldBeVisible,
      })
    } else {
      layerServiceC3.hideLayer(EXCLUDED_ASSETS)
      layerServiceC3.hideLayer(EXCLUDED_CLUSTER_ASSETS)
    }

    commit(MUTATIONS.SET_MAPUPDATEFLAGS, { assets: false })
    commit(MUTATIONS.SET_ASSETS_LOADING, false)

    if (isIntelligentMediaTypeDigitalStaticEnabled(APP_METADATA)) {
      const assetsProperties =
        analyzeAssetsForAllDigitalOrAllClassic(assetsToShow)
      commit(MUTATIONS.SET_ASSETS_DIGITAL_STATIC_PROPERTIES, assetsProperties)
    }

    console.debug('🟢 Updating Assets Layer - OK')
  },
  [ACTIONS.UPDATE_AUDIENCE_DELIVERY]: ({ commit }, delivery) => {
    commit(MUTATIONS.SET_AUDIENCE_DELIVERY, delivery)
  },
  [ACTIONS.LOAD_PACKAGES]: async ({ commit }) => {
    const packages = await PackageService.getAllPackages(
      APP_METADATA,
      BrowserEnvironmentResolver.getInstance(),
    )
    commit(MUTATIONS.SET_PACKAGES_DATA, packages)
  },
  [ACTIONS.UPDATE_ASSETS_FACING_ORIENTATION_ICONS]: ({ commit }, enabled) => {
    commit(MUTATIONS.SET_ASSETS_FACING_ORIENTATION_ICONS, enabled)
  },
  [ACTIONS.UPDATE_POIS_BUFFER_VISIBILITY]: ({ commit }, enabled) => {
    commit(MUTATIONS.SET_POIS_BUFFER_VISIBILITY, enabled)
  },
  [ACTIONS.UPDATE_POIS_VISIBILITY]: ({ commit }, enabled) => {
    commit(MUTATIONS.SET_POIS_VISIBILITY, enabled)
  },
  [ACTIONS.UPDATE_BOUNDARIES_VISIBILITY]: ({ commit }, enabled) => {
    commit(MUTATIONS.SET_BOUNDARIES_VISIBILITY, enabled)
  },
  [ACTIONS.UPDATE_AUDIENCES_QUINTILE_VISIBILITY]: ({ commit }, enabled) => {
    commit(MUTATIONS.SET_AUDIENCES_QUINTILE_VISIBILITY, enabled)
  },
  [ACTIONS.UPDATE_ASSETS_IDS_LAYER_VISIBILITY]: ({ commit }, enabled) => {
    commit(MUTATIONS.SET_ASSETS_IDS_LAYER_VISIBILITY, enabled)
  },
  [ACTIONS.UPDATE_COLORIZE_ASSETS]: ({ commit }, enabled) => {
    commit(MUTATIONS.SET_COLORIZE_ASSETS, enabled)
  },
  [ACTIONS.UPDATE_BASEMAP]: ({ commit }, baseMapId) => {
    commit(MUTATIONS.SET_TRANSITION_BETWEEN_BASEMAPS, true)
    commit(MUTATIONS.SET_BASEMAP, baseMapId)
  },
  [ACTIONS.REMOVE_PACKAGE_SELECTION]: async (
    { commit, getters },
    packageId,
  ) => {
    const allPackages = getters[GETTERS.GET_PACKAGES_DATA]
    const packageFilter = getters[GETTERS_PLAN.GET_PACKAGE]
    const packagesSelected = packageFilter.packages.filter(
      (packageSelected) => packageSelected.id !== packageId,
    )
    const updatedFilter = await preparePackageSelection(
      packagesSelected,
      allPackages,
      packageFilter,
    )
    commit(MUTATIONS.SET_PACKAGE, updatedFilter)
  },
  [ACTIONS.SET_PACKAGE_SELECTION]: ({ commit, getters }, payload) => {
    const actualPackageSelection = getters[GETTERS_PLAN.GET_PACKAGE]
    const packageSelectionUpdated = {
      ...actualPackageSelection,
      ...payload,
    }
    commit(MUTATIONS.SET_PACKAGE, packageSelectionUpdated)
  },
  [ACTIONS.UPDATE_PACKAGE_STATE]: async (
    { commit, getters, dispatch },
    payload,
  ) => {
    const { state: stateToUpdate } = payload
    const plan = getters[GETTERS_PLAN.GET_PLAN]
    const currentPlan = await PlanService.getPlan(
      APP_METADATA,
      BrowserEnvironmentResolver.getInstance(),
      plan.id,
    )
    const currentPackageState = currentPlan.package.state
    const packageState = plan.package.state

    if (packageState !== currentPackageState) {
      sendErrorMessageToUser({
        titleLocaleKey: 'ERROR_PLAN_UPDATE_STATE_TITLE',
        msgLocaleKey: 'ERROR_PLAN_UPDATE_LATEST_VERSION',
      })
      throw new Error(
        'The package state cannot be updated because user is not using current version of the plan',
      )
    }

    let finalStateToUpdate = stateToUpdate

    if (!isStateUpdateable(packageState, stateToUpdate, plan)) {
      sendErrorMessageToUser({
        titleLocaleKey: 'ERROR_PLAN_UPDATE_STATE_TITLE',
        msgLocaleKey: 'ERROR_PLAN_UPDATE_STATE_DESCRIPTION',
        title: 'Error updating package state',
        msg: 'The package state cannot be updated',
      })
      throw new Error(
        `The package state cannot be updated because state cannot be updated from ${packageState} to ${stateToUpdate}`,
      )
    }

    const auth0Instance = getAuth0ServiceIntance()
    const userId = auth0Instance.getUser()
    const operationTimestamp = new Date()
    plan.package.actions.push({
      state: stateToUpdate,
      timestamp: operationTimestamp,
      user: userId,
    })

    switch (stateToUpdate) {
      case Package.State.BookingRequest:
        plan.package.bookedConfirmByAdminAt = undefined
        plan.package.expirationForAgencyBookingConfirmation = undefined
        break
      case Package.State.BookingConfirmedByAdmin:
        plan.package.bookedConfirmByAdminAt = operationTimestamp
        plan.package.expirationForAgencyBookingConfirmation =
          getExpirationForAgencyBookingConfirmation(
            operationTimestamp,
            getPackageExpireConfirmationTime(APP_METADATA),
          )
        break
      case Package.State.ReBooking:
        plan.package.bookedConfirmByAdminAt = undefined
        plan.package.expirationForAgencyBookingConfirmation = undefined
        finalStateToUpdate = Package.State.BookingRequest
        break
      case Package.State.Booked:
        plan.package.bookedFinalizedAt = operationTimestamp
        plan.package.expirationForAgencyCancellation =
          getExpirationForAgencyCancellation(
            plan.package.filter.dateRange[0],
            getPackageExpireCancelTime(APP_METADATA),
          )
        break
    }

    // Update package state on state
    commit(MUTATIONS.SET_PACKAGE_STATE, finalStateToUpdate)
    // Update package on Firestore
    const payloadForUpdate = {
      plan,
      id: plan.id,
    }
    await dispatch(ACTIONS.UPDATE_PLAN, payloadForUpdate)

    // Send email
  },
  [ACTIONS.UPDATE_PACKAGE_AVAILABILITY]: async (
    { commit, getters },
    payload,
  ) => {
    const plan = getters[GETTERS_PLAN.GET_PLAN]
    if (!plan.package) {
      return
    }
    const { availability: availabilityToUpdate } = payload

    // Update package state on state
    commit(MUTATIONS.SET_PACKAGE_AVAILABILITY, availabilityToUpdate)
  },
  [ACTIONS.UPDATE_PACKAGE_PRICE]: async ({ commit, getters }, payload) => {
    const plan = getters[GETTERS_PLAN.GET_PLAN]
    if (!plan.package) {
      return
    }

    const priceUpdated = getPriceValueUpdated(payload)

    commit(MUTATIONS.SET_PACKAGE_PRICE, priceUpdated)
  },
  [ACTIONS.UPDATE_PLAN_FILTER]: async ({ commit }, payload) => {
    /*
    Payload:
       - filters: Array of filters
       - flagToSetDirty?: must be ['assets', 'pois', 'geoboundaries']
    */

    const filtersUpdated = payload.filters
    if (!filtersUpdated) {
      throw new Error('Filters are required')
    }
    if (!Array.isArray(filtersUpdated)) {
      throw new Error('Filters must be an array')
    }
    if (filtersUpdated.length === 0) {
      throw new Error('Filters cannot be empty')
    }

    const validFlags = ['assets', 'pois', 'geoboundaries']
    const flagToSetDirty = payload.flagToSetDirty
    if (flagToSetDirty && !validFlags.includes(flagToSetDirty)) {
      throw new Error(
        'Invalid flag to set dirty, available values are: ',
        validFlags,
      )
    }

    commit(MUTATIONS.SAVE_PLAN_FILTER, {
      filters: filtersUpdated,
    })

    if (flagToSetDirty) {
      commit(MUTATIONS.SET_MAPUPDATEFLAGS, { [flagToSetDirty]: true })
    }
  },
}

export function assetsParseFilters(currentFilters) {
  const parsedFilters = serializeFilters(currentFilters)

  const parsedFiltersWithoutMeta = parsedFilters.map((subPlanFilters) => {
    const filters = {}
    for (const key in subPlanFilters) {
      if (!key.startsWith('meta')) {
        filters[key] = subPlanFilters[key]
      }
    }
    return filters
  })
  return parsedFiltersWithoutMeta
}
