export var AudienceType;
(function (AudienceType) {
    AudienceType["Index"] = "index";
    AudienceType["Percentage"] = "percent";
})(AudienceType || (AudienceType = {}));
export var AudienceFeatureVersion;
(function (AudienceFeatureVersion) {
    AudienceFeatureVersion[AudienceFeatureVersion["V1"] = 1] = "V1";
    AudienceFeatureVersion[AudienceFeatureVersion["V2"] = 2] = "V2";
})(AudienceFeatureVersion || (AudienceFeatureVersion = {}));
