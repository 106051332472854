import { Environment } from '@workspaces/types';
export default class BrowserEnvironmentResolver {
    environment;
    testing;
    static instance;
    constructor() {
        this.environment = process.env.VUE_APP_ENV;
        this.testing = process.env.VUE_APP_TESTING;
    }
    getLocation() {
        throw new Error('BrowserEnvironmentResolver has no location');
    }
    static getInstance() {
        if (!BrowserEnvironmentResolver.instance) {
            BrowserEnvironmentResolver.instance = new BrowserEnvironmentResolver();
        }
        return BrowserEnvironmentResolver.instance;
    }
    getEnvironment() {
        switch (this.environment) {
            case 'development':
                return Environment.Environment.Development;
            case 'staging':
                return Environment.Environment.Staging;
            case 'production':
                return Environment.Environment.Production;
            default:
                throw new Error(`Unknown environment for value [${this.environment}]. Check .env file and VUE_APP_ENV variable`);
        }
    }
    getEnvironmentForBQ() {
        const environment = this.getEnvironment();
        return this.isTesting() ? `${environment}_test` : environment;
    }
    getEnvironmentVariable(name) {
        const environmentVaraibleValue = process.env[name];
        if (environmentVaraibleValue) {
            return environmentVaraibleValue;
        }
        throw new Error(`Environment variable ${name} not found`);
    }
    isTesting() {
        if (this.testing === undefined) {
            console.warn('VUE_APP_TESTING environment variable not found. Assuming false');
        }
        return this.testing === 'true';
    }
}
