function stringSortByAlphabeticalOrder(a, b) {
    if (a < b) {
        return -1;
    }
    else if (a > b) {
        return 1;
    }
    else {
        return 0;
    }
}
export function sortArrayByAlphabeticalOrder(content) {
    return content.sort((a, b) => stringSortByAlphabeticalOrder(a, b));
}
export function isArrayEmpty(array) {
    try {
        const length = array.length;
        return length === 0;
    }
    catch (error) {
        // eslint-disable-next-line no-console
        console.warn('Checking if an array is empty but its undefined.\nConsidering it empty', error);
        return true;
    }
}
export function isNotEmptyArray(array) {
    if (!array) {
        return false;
    }
    if (!Array.isArray(array)) {
        return false;
    }
    return array.length > 0;
}
