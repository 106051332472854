import GeometryService from '@/services/geoboundary.service';
export function hasOptimizeDistributionFilter(filters) {
    const firstSubFilter = filters[0];
    if (firstSubFilter.geographical_distribution) {
        // check if any region is active
        const activeRegions = Object.values(firstSubFilter.geographical_distribution.maxFilters).filter((region) => region.active);
        if (activeRegions.length > 0) {
            return true;
        }
        // check if distance filter is active
        if (firstSubFilter.geographical_distribution.distanceFilterActive) {
            return true;
        }
    }
    return false;
}
export async function getOptimizeDistributionRegions(metadata, environment, filters) {
    const filter = filters[0];
    const metadataRegionInfo = metadata.data_model.regions.regions_hierarchy;
    // prepare base params
    const countryISO2Ids = metadata.countries
        .filter((country) => filter.countries.includes(country.id))
        .map((country) => country.iso2);
    const activeRegions = Object.entries(filter.geographical_distribution.maxFilters)
        .map(([key, region]) => {
        return {
            id: Number(key.replace('region_', '')),
            type: key,
            ...region,
        };
    })
        .filter((region) => region.active);
    const activeFullRegions = activeRegions
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        .map((region) => {
        const metaRegion = metadataRegionInfo[region.id];
        const subFilterBoundariesRegion = filter.geoboundaries[region.type];
        return {
            filterIds: subFilterBoundariesRegion
                ? subFilterBoundariesRegion.map((b) => b.id)
                : [],
            id: region.id,
            geom: metaRegion ? metaRegion.geom : true,
            type: region.type,
        };
    });
    // fetch regions
    let geographicalDistributionRegionsResult = [];
    if (activeFullRegions.length) {
        const queryParams = {
            active_regions: activeFullRegions,
            filter_regions: activeFullRegions,
            country_iso2: countryISO2Ids,
        };
        geographicalDistributionRegionsResult =
            await GeometryService.getGeoboundariesByTypeAndCountry(metadata, environment, queryParams);
    }
    // prepare result
    const regionFieldNames = Object.values(metadataRegionInfo).reduce((acc, region) => {
        acc[region.id] = region.name;
        return acc;
    }, {});
    const geographicalDistributionRegions = geographicalDistributionRegionsResult.map((region) => {
        return {
            ...region,
            field_name: regionFieldNames[region.type_id],
        };
    });
    return geographicalDistributionRegions || [];
}
