import { MODULE_NAME_MESSAGES, TypeOfMessage } from '@/store/messages';
import { commit } from '@/utils/vuex';
import { MUTATIONS } from '@/store/messages/messages.constants';
import { getLocaleValueFor } from '@/helpers/locale.helper';
const titleGenericLocalKey = 'MESSAGE_GENERIC_TITLE';
const msgGenericLocalKey = 'MESSAGE_GENERIC_MESSAGE';
function sendMessageToUser(message) {
    commit(MODULE_NAME_MESSAGES, MUTATIONS.SET_MESSAGE, {
        show: true,
        typeOfMessage: message.type,
        title: message.title,
        msg: message.msg,
        action: () => {
            return 0;
        },
    });
}
function getMessageFromParams(params, typeOfMessage) {
    const typeOfMessageLocale = getLocaleValueFor(typeOfMessage);
    let title = `${typeOfMessageLocale} - ${getLocaleValueFor(titleGenericLocalKey)}`;
    let msg = getLocaleValueFor(msgGenericLocalKey);
    if (params.titleLocaleKey) {
        try {
            title = getLocaleValueFor(params.titleLocaleKey, true);
        }
        catch (error) {
            console.warn(error);
        }
    }
    else {
        title = params.title || title;
    }
    if (params.msgLocaleKey) {
        try {
            msg = getLocaleValueFor(params.msgLocaleKey, true);
        }
        catch (error) {
            console.warn(error);
        }
    }
    else {
        msg = params.msg || msg;
    }
    if (params.msgDynamicValues) {
        params.msgDynamicValues.forEach((value, index) => {
            msg = msg.replace(`{${index}}`, value);
        });
    }
    return {
        type: typeOfMessage,
        title,
        msg,
    };
}
export function sendInfoMessageToUser(params) {
    const paramsFormatted = getMessageFromParams(params, TypeOfMessage.Info);
    sendMessageToUser(paramsFormatted);
}
export function sendWarningMessageToUser(params) {
    const paramsFormatted = getMessageFromParams(params, TypeOfMessage.Warning);
    sendMessageToUser(paramsFormatted);
}
export function sendErrorMessageToUser(params) {
    const paramsFormatted = getMessageFromParams(params, TypeOfMessage.Error);
    sendMessageToUser(paramsFormatted);
}
