import { Auth } from '@workspaces/types';
function isRole(metadata, userInfo, roleToLookup) {
    const roles = metadata.roles;
    if (roles.length === 0) {
        console.debug('No roles defined in metadata, managing all users as Admin');
        return true;
    }
    const roleDefinition = roles.find((role) => role.role === roleToLookup);
    if (!roleDefinition) {
        return false;
    }
    const rolesData = userInfo[roleDefinition.token_property];
    if (!rolesData) {
        console.warn(`Expected role data under property ${roleDefinition.token_property} not found in user info`, userInfo);
        return false;
    }
    if (Array.isArray(rolesData)) {
        const roleFound = rolesData.some((role) => role === roleDefinition.value);
        return roleFound;
    }
    console.warn(`Expected role data under property ${roleDefinition.token_property} to be an array, but it is not`, userInfo);
    return false;
}
function isPowerUser(metadata, userInfo) {
    return isRole(metadata, userInfo, Auth.Role.PowerUser);
}
function isAdmin(metadata, userInfo) {
    return isRole(metadata, userInfo, Auth.Role.Admin);
}
function isUser(metadata, userInfo) {
    return isRole(metadata, userInfo, Auth.Role.User);
}
export function getRole(metadata, userInfo) {
    if (isAdmin(metadata, userInfo)) {
        return Auth.Role.Admin;
    }
    if (isPowerUser(metadata, userInfo)) {
        return Auth.Role.PowerUser;
    }
    if (isUser(metadata, userInfo)) {
        return Auth.Role.User;
    }
    return Auth.Role.Unknown;
}
