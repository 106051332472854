import { Auth, Package } from '@workspaces/types';
import ResolverBasic from './resolver-basic';
/**
 * 📢 Admin role represents someone from CCF-Admin agency
 */
class PermissionResolverFI extends ResolverBasic {
    getPackageFromPlan(plan) {
        if (plan.package === undefined || plan.package === null) {
            throw new Error('Plan package is not defined');
        }
        if (typeof plan.package === 'string') {
            throw new Error('Plan package is not deserialized');
        }
        return plan.package;
    }
    canUploadCustomPOIs() {
        return true;
    }
    canEditAnyCustomPOIGroup() {
        return false;
    }
    permitComments(planOwner) {
        return planOwner === this.getName() || Auth.Role.Admin === this.getRole();
    }
    canSavePlan(planOwner, planId, 
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    isPlanPublic) {
        if (!planId) {
            return true;
        }
        return this.getName() === planOwner || this.getRole() === Auth.Role.Admin;
    }
    canSavePlanAsPublic() {
        return true;
    }
    static DeleteablePlanStates = [
        Package.State.Draft,
        Package.State.Expired,
        Package.State.Cancelled,
    ];
    canDeletePlan(plan) {
        const planPackage = this.getPackageFromPlan(plan);
        const planOwner = plan.created_by;
        const userCanDelete = this.getName() === planOwner || this.getRole() === Auth.Role.Admin;
        const isPlanDeletable = PermissionResolverFI.DeleteablePlanStates.includes(planPackage.state);
        return userCanDelete && isPlanDeletable;
    }
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    canDeleteCustomGeoboundary(owner) {
        return false;
    }
    canDeleteCustomPOIGroup(owner) {
        return this.isOwnerOrAdmin(owner);
    }
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    canUpdateCustomPOIGroup(owner) {
        return false;
    }
    canEditPlanPrice(plan) {
        const packageInfo = plan.package;
        if (!packageInfo) {
            return false;
        }
        if (typeof packageInfo === 'string') {
            throw new Error('Plan package is not deserialized');
        }
        if (packageInfo.state === Package.State.Draft) {
            return true;
        }
        return this.getRole() === Auth.Role.Admin;
    }
    canBookPlanPackage(plan) {
        const planPackage = this.getPackageFromPlan(plan);
        if (this.isAnonymous()) {
            return false;
        }
        const arePackagesSet = planPackage.packages && planPackage.packages.length > 0;
        return (!this.isAdmin() &&
            planPackage.state === Package.State.Draft &&
            arePackagesSet);
    }
    canConfirmBookingPlanPackageByAdmin(plan) {
        const planPackage = this.getPackageFromPlan(plan);
        return this.isAdmin() && planPackage.state === Package.State.BookingRequest;
    }
    canConfirmBookingPlanPackageByAgency(plan) {
        const planPackage = this.getPackageFromPlan(plan);
        if (!this.isAdmin() &&
            planPackage.state === Package.State.BookingConfirmedByAdmin) {
            const expirationTime = planPackage.expirationForAgencyBookingConfirmation;
            if (expirationTime === undefined) {
                console.warn('Trying to decide is plan can be confirmed without expiration booking timestamp');
                return false;
            }
            const nowInMillis = new Date().getTime();
            const isExpired = nowInMillis - expirationTime.getTime() >= 0;
            return !isExpired;
        }
        return false;
    }
    canFinalizeConfirmBookingPlanPackageByAdmin(plan) {
        const planPackage = this.getPackageFromPlan(plan);
        return (this.isAdmin() &&
            planPackage.state === Package.State.BookingConfirmedByAgency);
    }
    canCancelPlanPackageByAdmin(plan) {
        const planPackage = this.getPackageFromPlan(plan);
        // if (!this.isAdmin() && plan.package.state === Package.State.Booked) {
        //   const expirationTime = plan.package.expirationForAgencyCancellation
        //   if (expirationTime === undefined) {
        //     console.warn(
        //       'Trying to decide is plan can be confirmed without an expiraction cancellation  timestamp',
        //     )
        //     return false
        //   }
        //   const nowInMillis = new Date().getTime()
        //   const isExpired = nowInMillis - expirationTime.getTime() > 0
        //   return !isExpired
        // }
        const expectedState = planPackage.state === Package.State.BookingConfirmedByAdmin ||
            planPackage.state === Package.State.Booked;
        return this.isAdmin() && expectedState;
    }
    canShowWidgetUploadAssets() {
        return this.isAdmin();
    }
    static EditableDatesStates = [
        Package.State.Draft,
        Package.State.BookingRequest,
        Package.State.ReBooking,
    ];
    canEditPlanPackageDates(plan) {
        const planPackage = this.getPackageFromPlan(plan);
        const planPackageState = planPackage.state;
        return PermissionResolverFI.EditableDatesStates.includes(planPackageState);
    }
}
export default PermissionResolverFI;
