export var POIsClasification;
(function (POIsClasification) {
    POIsClasification["Category"] = "categories";
    POIsClasification["Brand"] = "brands";
    POIsClasification["Locations"] = "locations";
    POIsClasification["CustomPOIs"] = "custom";
    POIsClasification["Tags"] = "tags";
})(POIsClasification || (POIsClasification = {}));
export var POIsFilterMethod;
(function (POIsFilterMethod) {
    POIsFilterMethod["Radius"] = "RADIUS";
    POIsFilterMethod["Walking"] = "WALKING";
    POIsFilterMethod["Driving"] = "DRIVING";
})(POIsFilterMethod || (POIsFilterMethod = {}));
export var POIsFilterOperation;
(function (POIsFilterOperation) {
    POIsFilterOperation["Union"] = "union";
    POIsFilterOperation["Intersection"] = "intersection";
})(POIsFilterOperation || (POIsFilterOperation = {}));
export var CustomPOIsImportMode;
(function (CustomPOIsImportMode) {
    CustomPOIsImportMode["Coordinates"] = "COORDINATES";
    CustomPOIsImportMode["Geocode"] = "GEOCODE";
})(CustomPOIsImportMode || (CustomPOIsImportMode = {}));
