export function sortData(data, { field, order }) {
    const sortedData = [...data];
    if (order === 'asc') {
        sortedData.sort((a, b) => {
            if (a[field] < b[field]) {
                return -1;
            }
            else if (a[field] > b[field]) {
                return 1;
            }
            else {
                return 0;
            }
        });
    }
    else if (order === 'desc') {
        sortedData.sort((a, b) => {
            if (a[field] > b[field]) {
                return -1;
            }
            else if (a[field] < b[field]) {
                return 1;
            }
            else {
                return 0;
            }
        });
    }
    return sortedData;
}
