import { PackagesService } from '@workspaces/services';
export default {
    async getAllPackages(meta, environment) {
        const data = await PackagesService.getAllPackagesWithAllProperties(meta, environment);
        return data;
    },
    async getPackageAvailability(meta, environment, startDate, endDate, packageId) {
        const data = await PackagesService.getPackageAvailability(meta, environment, startDate, endDate, packageId);
        return data;
    },
    async getPackageClassificationValues(meta, environment) {
        const data = await PackagesService.getPackageClassificationValues(meta, environment);
        return data;
    },
};
