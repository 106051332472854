import { GeoboundaryService } from '@workspaces/services';
import { isMultiCountry } from '@/plan/metadata/metadata.helper';
function sortAlphabeticallyAutoCompleteResult(a, b) {
    if (a.name < b.name) {
        return -1;
    }
    if (a.name > b.name) {
        return 1;
    }
    return 0;
}
export default {
    async getValuesGroupByPattern(meta, environment, queryParams) {
        if (!isMultiCountry(meta)) {
            delete queryParams.countries_id;
        }
        const data = await GeoboundaryService.getValuesGroupByPattern(meta, environment, queryParams);
        data.forEach((element) => {
            element.data.sort(sortAlphabeticallyAutoCompleteResult);
        });
        return data;
    },
    async getGeometryByIds(meta, environment, queryParams) {
        const data = await GeoboundaryService.getGeometryByIds(meta, environment, queryParams);
        return data;
    },
    async getGeometryByNames(meta, environment, queryParams) {
        const data = await GeoboundaryService.getGeomertyByName(meta, environment, queryParams);
        return data;
    },
    async getGeoboundariesByTypeAndCountry(meta, environment, queryParams) {
        const data = await GeoboundaryService.getGeoboundariesByTypeAndCountry(meta, environment, queryParams);
        return data;
    },
};
