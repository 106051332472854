import { MUTATIONS } from './messages.constants';
import { defaultMessageState } from './state';
export const mutations = {
    [MUTATIONS.SET_MESSAGE]: (state, payload) => {
        state.show = payload.show;
        state.typeOfMessage = payload.typeOfMessage;
        state.title = payload.title;
        state.msg = payload.msg;
        state.action = payload.action;
    },
    [MUTATIONS.CLEAR_MESSAGE]: (state) => {
        state.action = defaultMessageState.action;
        state.msg = defaultMessageState.msg;
        state.show = defaultMessageState.show;
        state.typeOfMessage = defaultMessageState.typeOfMessage;
    },
};
