export var Role;
(function (Role) {
    Role["PowerUser"] = "power_user";
    Role["Admin"] = "admin";
    Role["User"] = "user";
    Role["Unknown"] = "unknown";
    Role["Anonymous"] = "anonymous";
})(Role || (Role = {}));
export var PermissionResolver;
(function (PermissionResolver) {
    PermissionResolver["US"] = "US";
    PermissionResolver["EU"] = "EU";
    PermissionResolver["FI"] = "FI";
})(PermissionResolver || (PermissionResolver = {}));
