import { getAppMetadata } from '../metadata/metadata';
import AssetsService from '@/services/assets.service';
import { IMPRESSIONS } from '../constants';
export function getImpressionsForAssets(data) {
    let impressions = 0;
    data.forEach((asset) => {
        if (!asset.properties.excluded) {
            impressions += asset.properties.impressions ?? 0;
        }
    });
    return impressions;
}
export function getAssetDataGeoJsonLayerPropertiesKey(key) {
    return `${key}_id`;
}
export function setExcludedPropertyToAssetsBySubFilter(allAssets, assets, excludedIds) {
    const excludedAssets = [];
    const assetsWithoutExcluded = assets.filter((asset) => {
        const isExcludedAsset = excludedIds.includes(asset.properties.id);
        if (isExcludedAsset) {
            const assetExcluded = {
                ...asset,
                properties: { ...asset.properties, excluded: true },
            };
            excludedAssets.push(assetExcluded);
        }
        return !isExcludedAsset;
    });
    const assetsCombined = [...assetsWithoutExcluded, ...excludedAssets];
    let excludedAssetsOutsideFilter = [];
    if (excludedAssets.length > 0) {
        excludedAssetsOutsideFilter = excludedIds.filter((id) => {
            return !excludedAssets.find((asset) => asset.properties.id === id);
        });
    }
    else {
        excludedAssetsOutsideFilter = [...excludedIds];
    }
    const excludedAssetsOutsideFilterWithProperties = excludedAssetsOutsideFilter.map((id) => {
        const asset = allAssets.find((asset) => asset.properties.id === id);
        if (!asset) {
            throw new Error(`Asset with id ${id} not found`);
        }
        return {
            ...asset,
            properties: {
                ...asset.properties,
                excluded: true,
                excludedLayer: true,
            },
        };
    });
    const assetsCombinedWithExcludedOutsideFilter = [
        ...assetsCombined,
        ...excludedAssetsOutsideFilterWithProperties,
    ];
    return assetsCombinedWithExcludedOutsideFilter;
}
/**
 *
 * @param assets Assets filtered at the moment
 * @param includedIds Assets included by the user that are not taken into account by filter. These assets are included via the Excluded Assets Layer
 * @returns Filtered assets, plus the ones included manually by the user via the map
 */
export function addIncludedAssetsFromExcludeLayer(allAssets, assets, includedIds) {
    if (!includedIds || includedIds.length === 0) {
        return { assets, filterApplied: false };
    }
    const includedAssets = [];
    includedIds.forEach((id) => {
        const asset = assets.find((asset) => asset.properties.id === id);
        if (!asset) {
            const asset = allAssets.find((asset) => asset.properties.id === id);
            if (!asset) {
                throw new Error(`Asset with id ${id} not found`);
            }
            includedAssets.push(asset);
        }
    });
    const filteredAssets = [...assets, ...includedAssets];
    return { assets: filteredAssets, filterApplied: true };
}
function filterAssetsByInventoryOnMode(assets, filterParam, exclusionMode) {
    const filteredAssets = assets.filter((asset) => {
        const { properties } = asset;
        let value = null;
        if (filterParam.field === 'PlantUnitId') {
            value = properties.name?.toString() || null;
        }
        else if (filterParam.field === 'GeopathId') {
            value = properties.external_panel_id?.toString() || null;
        }
        else {
            value = properties.internal_panel_id?.toString() || null;
        }
        if (!value) {
            return false;
        }
        if (exclusionMode) {
            return !filterParam.ids.includes(value);
        }
        return filterParam.ids.includes(value);
    });
    return filteredAssets;
}
function filterAssetsByInventoryIncluded(assets, subfilter) {
    const filteredAssets = filterAssetsByInventoryOnMode(assets, subfilter.panels, false);
    return { assets: filteredAssets, filterApplied: true };
}
function fillRegionsWithAssets(meta, assets, regions) {
    const regionsWithAssetsArray = regions.map((region) => {
        if (region.type_id === 1) {
            const regionNameMeta = meta.countries.find((country) => country.id_legacy === region.id);
            return { ...region, assets: [], id_meta: regionNameMeta?.id || -1 };
        }
        else {
            return { ...region, assets: [], id_meta: -1 };
        }
    });
    assets.forEach((asset) => {
        const { properties } = asset;
        regionsWithAssetsArray.forEach((region) => {
            const regionKey = getAssetDataGeoJsonLayerPropertiesKey(region.field_name.toLocaleLowerCase());
            const assetRegionFieldId = properties[regionKey];
            if (region.type_id === 1) {
                // COUNTRIES
                if (assetRegionFieldId === region.id_meta) {
                    region.assets.push(asset);
                }
            }
            else {
                // OTHER REGIONS
                if (region.id === assetRegionFieldId) {
                    region.assets.push(asset);
                }
            }
        });
    });
    return regionsWithAssetsArray.filter((region) => region.assets.length > 0);
}
function sortRegionsWithAssets(regionsWithAssets, subfilter) {
    if (subfilter.geographical_distribution.optimizeBy === IMPRESSIONS) {
        // BY IMPRESSIONS
        regionsWithAssets.forEach((region) => {
            region.assets.sort((a, b) => {
                return b.properties.impressions - a.properties.impressions;
            });
        });
    }
    else {
        // BY AUDIENCE INDEX
        regionsWithAssets.forEach((region) => {
            region.assets.sort((a, b) => {
                return b.properties.audienceIndex - a.properties.audienceIndex;
            });
        });
    }
    return regionsWithAssets;
}
function sortAssets(assets, subfilter) {
    const sortedAssets = [...assets];
    if (subfilter.geographical_distribution.optimizeBy === IMPRESSIONS) {
        // BY IMPRESSIONS
        sortedAssets.sort((a, b) => {
            return b.properties.impressions - a.properties.impressions;
        });
    }
    else {
        // BY AUDIENCE INDEX
        sortedAssets.sort((a, b) => {
            return b.properties.audienceIndex - a.properties.audienceIndex;
        });
    }
    return sortedAssets;
}
async function filterRegionsAssetsByMinimumDistance(environment, subfilter, regionsWithAssets) {
    if (subfilter.geographical_distribution.distanceFilterActive) {
        const distance = subfilter.geographical_distribution.distanceFilter;
        const optimizeBy = subfilter.geographical_distribution.optimizeBy;
        console.debug(`               Calculating min. distance [${distance}] between assets by [${optimizeBy}]  ...`);
        let index = 0;
        for (const region of regionsWithAssets) {
            const regionAssetsIdsObj = await getAssetsByMinimumDistance(environment, region.assets, distance, optimizeBy);
            const assetsIds = regionAssetsIdsObj.map((asset) => asset.asset_id);
            regionsWithAssets[index].assets = region.assets.filter((asset) => assetsIds.includes(asset.properties.id));
            index++;
            console.debug(`                     ${region.name} || Assets: ${region.assets.length}`);
        }
        sortRegionsWithAssets(regionsWithAssets, subfilter);
    }
}
async function filterAssetsByMinimumDistance(environment, subfilter, assets) {
    if (subfilter.geographical_distribution.distanceFilterActive) {
        const distance = subfilter.geographical_distribution.distanceFilter;
        const optimizeBy = subfilter.geographical_distribution.optimizeBy;
        console.debug(`               Calculating min. distance [${distance}] between assets by [${optimizeBy}]  ...`);
        const regionAssetsIdsObj = await getAssetsByMinimumDistance(environment, assets, distance, optimizeBy);
        const assetsIds = regionAssetsIdsObj.map((asset) => asset.asset_id);
        assets = assets.filter((asset) => assetsIds.includes(asset.properties.id));
        sortAssets(assets, subfilter);
    }
    return assets;
}
function sortAssetsByOptimizationType(assets, optimizeBy) {
    if (optimizeBy === 'impressions') {
        return assets.sort((a, b) => {
            return b.properties.impressions - a.properties.impressions;
        });
    }
    else {
        return assets.sort((a, b) => {
            return b.properties.audienceIndex - a.properties.audienceIndex;
        });
    }
}
async function getAssetsByMinimumDistance(environment, assets, distance, optimizeBy) {
    const metadata = getAppMetadata();
    const sortedAssets = sortAssetsByOptimizationType(assets, optimizeBy);
    const assetsIds = sortedAssets.map((asset) => asset.properties.id);
    return await AssetsService.getAssetsByMinimumDistance(metadata, environment, assetsIds, distance);
}
function truncateRegionsWithAssets(meta, regionsWithAssets, subfilter) {
    const maxFilters = subfilter.geographical_distribution.maxFilters;
    const regionExceptions = subfilter.geographical_distribution.regionExceptions;
    regionsWithAssets.forEach((region) => {
        if (regionExceptions && regionExceptions[region.id] !== undefined) {
            const maxValue = regionExceptions[region.id].value;
            // truncate assets by exceptions without distance between assets
            region.assets = region.assets.slice(0, maxValue);
        }
        else {
            // truncate assets by max filters
            const regionInfo = Object.entries(meta.data_model.regions.regions_hierarchy).find((r) => r[1].id === region.type_id);
            if (regionInfo) {
                const regionKey = regionInfo[0];
                const filterInfo = maxFilters[regionKey];
                if (filterInfo.active) {
                    region.assets = region.assets.slice(0, filterInfo.max);
                }
            }
        }
    });
    return regionsWithAssets;
}
function mergeAllRegionsWithAssets(regionsWithAssets) {
    const assets = [];
    const allAssets = regionsWithAssets.reduce((acc, region) => {
        return [...acc, ...region.assets];
    }, []);
    const mapAssets = new Map();
    for (const asset of allAssets) {
        if (!mapAssets.has(asset.properties.id)) {
            mapAssets.set(asset.properties.id, true); // set any value to Map
            assets.push(asset);
        }
    }
    return assets;
}
function flatAndUniqueAssetsAudience(assetsAudience) {
    const assetsAudienceFlat = assetsAudience.flat(2);
    const assetsAudienceUnique = [];
    const mapAssetAudience = new Map();
    for (const assetAudience of assetsAudienceFlat) {
        if (!mapAssetAudience.has(assetAudience.asset_id)) {
            mapAssetAudience.set(assetAudience.asset_id, true); // set any value to Map
            assetsAudienceUnique.push(assetAudience);
        }
    }
    return assetsAudienceUnique;
}
function sortGroupRegionsByPriority(meta, regions) {
    const types = [...new Set(regions.map((r) => r.type_id))];
    if (types.length === 1) {
        return [regions];
    }
    else {
        const groupsRegions = types.reverse().map((rtype) => {
            return regions.filter((r) => r.type_id === rtype);
        });
        return groupsRegions;
    }
}
export async function filterAssetsByOptimizeDistribution(meta, environment, assets, subfilter, regions, assetsAudience) {
    let optimizeAssets = [...assets];
    // check if geographical distribution filter is active
    if (!subfilter.geographical_distribution ||
        (subfilter.geographical_distribution.maxFilterActive === false &&
            subfilter.geographical_distribution.distanceFilterActive === false)) {
        return { assets: optimizeAssets, filterApplied: false };
    }
    // check active regions in geographical distribution filter
    if (regions.length === 0 &&
        subfilter.geographical_distribution.distanceFilterActive === false) {
        return { assets: optimizeAssets, filterApplied: false };
    }
    const t0 = new Date().getTime();
    console.debug('     🔍 Optimize distribution || Starting ...');
    const initialAssetsElements = assets.length;
    let filteredAssets = [];
    // OPTIMIZE MAXIMUM NUMBER OF ASSETS BY REGION
    if (subfilter.geographical_distribution.maxFilterActive) {
        // prepare assets with audience index
        if (subfilter.geographical_distribution.optimizeBy === 'audience') {
            console.debug('               Loading audience indexes ...');
            // merge assets with audience indexes
            const mergedAssetsAudience = flatAndUniqueAssetsAudience(assetsAudience);
            // populate assets with audience index
            if (mergedAssetsAudience.length) {
                optimizeAssets.forEach((asset) => {
                    const assetAudience = mergedAssetsAudience.find((assetAudience) => assetAudience.asset_id === asset.properties.id);
                    if (assetAudience) {
                        asset.properties.audienceIndex = assetAudience.index;
                    }
                });
            }
        }
        // ONLY DISTANCE BETWEEN ASSETS
        if (subfilter.geographical_distribution.distanceFilterActive) {
            const distance = subfilter.geographical_distribution.distanceFilter;
            const optimizeBy = subfilter.geographical_distribution.optimizeBy;
            const assetsIdsObj = await getAssetsByMinimumDistance(environment, optimizeAssets, distance, optimizeBy);
            const assetsIds = assetsIdsObj.map((asset) => asset.asset_id);
            optimizeAssets = optimizeAssets.filter((asset) => assetsIds.includes(asset.properties.id));
        }
        // filters regions by region priority
        const sortedGroupsOfRegions = sortGroupRegionsByPriority(meta, regions);
        if (sortedGroupsOfRegions.length) {
            for (const regionsFiltered of sortedGroupsOfRegions) {
                console.debug('               ---------------------------------');
                // filters assets by regions
                console.debug('               Filling regions with assets ...');
                const regionsWithAssets = fillRegionsWithAssets(meta, optimizeAssets, regionsFiltered);
                regionsWithAssets.forEach((region) => {
                    console.debug(`                     ${region.name} || Assets: ${region.assets.length}`);
                });
                // sort assets by impressions or audience index
                sortRegionsWithAssets(regionsWithAssets, subfilter);
                console.debug('               Sorting assets of each region ...');
                regionsWithAssets.forEach((region) => {
                    console.debug(`                     ${region.name} || Assets: ${region.assets.length}`);
                });
                // try to filter assets by minimum distance
                await filterRegionsAssetsByMinimumDistance(environment, subfilter, regionsWithAssets);
                // truncate each region assets by max filters
                truncateRegionsWithAssets(meta, regionsWithAssets, subfilter);
                console.debug('               Truncating assets of each region ...');
                regionsWithAssets.forEach((region) => {
                    console.debug(`                     ${region.name} || Assets: ${region.assets.length}`);
                });
                // merge assets
                filteredAssets = mergeAllRegionsWithAssets(regionsWithAssets);
                optimizeAssets = filteredAssets;
            }
        }
        else {
            filteredAssets = await filterAssetsByMinimumDistance(environment, subfilter, optimizeAssets);
        }
    }
    else if (subfilter.geographical_distribution.distanceFilterActive) {
        const distance = subfilter.geographical_distribution.distanceFilter;
        const optimizeBy = subfilter.geographical_distribution.optimizeBy;
        console.debug(`               Calculating min. distance [${distance}] between assets by [${optimizeBy}]  ...`);
        const assetsIdsObj = await getAssetsByMinimumDistance(environment, optimizeAssets, distance, optimizeBy);
        const assetsIds = assetsIdsObj.map((asset) => asset.asset_id);
        filteredAssets = optimizeAssets.filter((asset) => assetsIds.includes(asset.properties.id));
    }
    console.debug(`     🔍 Optimize distribution || IN: ${initialAssetsElements}  OUT: ${filteredAssets.length}    TIME: ${new Date().getTime() - t0}`);
    return { assets: filteredAssets, filterApplied: true };
}
export function filterAssetsByInventory(allAssets, assets, subFilter) {
    let filterApplied = false;
    const inventoryIncludedPartialResult = filterAssetsByInventoryIncluded(assets, subFilter);
    filterApplied = inventoryIncludedPartialResult.filterApplied || filterApplied;
    return { assets: inventoryIncludedPartialResult.assets, filterApplied };
}
export function filterAssetsByInventoryOverwrittingAssets(allAssets, subFilter) {
    let filterApplied = false;
    const inventoryIncludedPartialResult = filterAssetsByInventoryIncluded(allAssets, subFilter);
    filterApplied = inventoryIncludedPartialResult.filterApplied || filterApplied;
    return { assets: inventoryIncludedPartialResult.assets, filterApplied };
}
