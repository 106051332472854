import { getAppMetadata } from '@/plan/metadata/metadata';
export var TypeOfMessage;
(function (TypeOfMessage) {
    TypeOfMessage["Info"] = "MESSAGE_INFO";
    TypeOfMessage["Warning"] = "MESSAGE_WARNING";
    TypeOfMessage["Error"] = "MESSAGE_ERROR";
})(TypeOfMessage || (TypeOfMessage = {}));
const appMetadata = getAppMetadata();
export const defaultMessageState = {
    metadata: appMetadata,
    show: false,
    typeOfMessage: TypeOfMessage.Info,
    title: 'Unset Message',
    msg: '',
    action: () => {
        return 0;
    },
};
export const state = {
    ...defaultMessageState,
};
