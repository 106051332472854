export var StorageDestination;
(function (StorageDestination) {
    StorageDestination["LocalStorage"] = "localStorage";
    StorageDestination["SessionStorage"] = "sessionStorage";
})(StorageDestination || (StorageDestination = {}));
export function setValueToStorage(destination, key, value) {
    const encoder = new TextEncoder();
    const contentBytes = encoder.encode(value);
    const valueAsString = contentBytes.join(',');
    window[destination].setItem(key, valueAsString);
}
export function getValueFromStorage(destination, key) {
    const contentBytes = window[destination].getItem(key);
    if (!contentBytes) {
        return null;
    }
    const valueAsArray = contentBytes.split(',').map(Number);
    const uint8Array = new Uint8Array(valueAsArray);
    const decoder = new TextDecoder();
    const valueAsString = decoder.decode(uint8Array);
    return valueAsString;
}
export function removeValueFromStorage(destination, key) {
    window[destination].removeItem(key);
}
