import { CustomGeoboundaryService } from '@workspaces/services';
import { sortData } from '@/utils/sort';
export default {
    async getCustomGeoboundaries(meta, environment, userId) {
        let data = await CustomGeoboundaryService.getCustomGeoboundaries(meta, environment, userId);
        data = sortData(data, { field: 'created_at', order: 'desc' });
        return data;
    },
    async getCustomGeoboundary(meta, environment, datasetId) {
        const dataset = await CustomGeoboundaryService.getCustomGeoboundary(meta, environment, datasetId);
        const datasetFormatted = {
            ...dataset,
            id: dataset.id,
        };
        return datasetFormatted;
    },
    async createCustomGeoboundary(meta, environment, userId, dataset) {
        dataset.created_by = userId;
        const datasetId = await CustomGeoboundaryService.createCustomGeoboundary(meta, environment, dataset);
        return datasetId;
    },
    async updateCustomGeoboundary(meta, environment, datasetId, dataset) {
        return await CustomGeoboundaryService.updateCustomGeoboundary(meta, environment, datasetId, dataset);
    },
    async deleteCustomGeoboundary(meta, environment, datasetId) {
        return await CustomGeoboundaryService.deleteCustomGeoboundary(meta, environment, datasetId);
    },
    // FILE CUSTOM GEOBOUNDARIES
    // FILE CUSTOM GEOBOUNDARIES
    // FILE CUSTOM GEOBOUNDARIES
    async getSignedURLUploadFile(meta, environment, filename) {
        return await CustomGeoboundaryService.getSignedURLUploadFile(meta, environment, filename);
    },
    async uploadFileWithSignedURL(url, file) {
        await CustomGeoboundaryService.uploadFileWithSignedURL(url, file);
    },
    async storeFileDataJob(meta, environment, fileId, fileName) {
        return await CustomGeoboundaryService.storeFileDataJob(meta, environment, fileId, fileName);
    },
    async checkStatusStoreFileDataJob(meta, environment, jobId) {
        return await CustomGeoboundaryService.checkStatusStoreFileDataJob(meta, environment, jobId);
    },
    async moveStoredRawDataAndClean(meta, environment, fileId, fileName) {
        return await CustomGeoboundaryService.moveStoredRawDataAndClean(meta, environment, fileId, fileName);
    },
    async deleteStoredFileData(meta, environment, fileId) {
        return await CustomGeoboundaryService.deleteStoredFileData(meta, environment, fileId);
    },
    async isGeometryForFileIdAvailable(meta, environment, fileId) {
        const result = await CustomGeoboundaryService.isGeometryForFileIdAvailable(meta, environment, fileId);
        return result;
    },
};
