import { getPackageAvailability } from '@/services/packages-browser.service';
import { getPriceValueUpdated, preparePriceModel } from './price.helper';
/**
 * Get the availability of a list of packages
 * @param packages  List of packages to resolve the availability
 * @returns High if all packages have high availability, Low if all packages have low availability, Medium if the availability is mixed
 */
function resolvePackagesAvailability(packages) {
    if (packages.length === 0) {
        return null;
    }
    // Values for ranges are: 1, 41, 91
    const nomralizedConstantValues = [1, 41, 91];
    let totalAvailability = 0;
    packages.forEach((pkg) => {
        if (pkg.availability) {
            if (pkg.availability < 40) {
                totalAvailability += nomralizedConstantValues[0];
            }
            else if (pkg.availability < 90) {
                totalAvailability += nomralizedConstantValues[1];
            }
            else {
                totalAvailability += nomralizedConstantValues[2];
            }
        }
    });
    if (totalAvailability === nomralizedConstantValues[0] * packages.length) {
        return nomralizedConstantValues[0];
    }
    else if (totalAvailability === nomralizedConstantValues[2] * packages.length ||
        totalAvailability === 0) {
        return nomralizedConstantValues[2];
    }
    else {
        return nomralizedConstantValues[1];
    }
}
export async function getPackagesAvailability(appMetadata, environment, packages, dateRange) {
    if (!dateRange || dateRange.length !== 2) {
        return null;
    }
    if (!packages || packages.length === 0) {
        return null;
    }
    const [startDate, endDate] = dateRange;
    const availabilityPromises = packages.map((pkg) => getPackageAvailability(appMetadata, environment, startDate, endDate, pkg.id));
    const availabilities = await Promise.all(availabilityPromises);
    // Assign availabilities to packages
    packages.forEach((pkg, index) => {
        pkg.availability = availabilities[index];
    });
    const finalAvailability = resolvePackagesAvailability(packages);
    return finalAvailability;
}
export async function preparePackageSelection(selectedPackages, allPackages, currentFilter) {
    const filteredPackages = allPackages.filter((pkg) => selectedPackages.some((selected) => selected.id === pkg.package_id));
    const packages = [];
    let price = 0;
    let productionDefaultValue = 0;
    let assetAllocationDefaultValue = 0;
    let materialHandlingDefaultValue = 0;
    let assets = 0;
    let impressions = 0;
    selectedPackages.forEach((data) => {
        const packageDetails = filteredPackages.find((p) => p.package_id === data.id);
        if (packageDetails === undefined) {
            throw new Error('Package details not found for package id: ' + data.id);
        }
        packages.push({
            id: data.id,
            name: data.name,
            availability: data.availability,
            price: {
                price: packageDetails.gross_price,
                production: packageDetails.production,
                posting: packageDetails.posting,
            },
            digital: packageDetails.digital ?? false,
            assets: packageDetails.panels,
            impressions: packageDetails.contacts,
        });
        if (packageDetails === undefined) {
            throw new Error('Package details not found for package id: ' + data.id);
        }
        price += packageDetails.gross_price;
        productionDefaultValue += packageDetails.production;
        assetAllocationDefaultValue += !packageDetails.digital
            ? packageDetails.posting
            : 0;
        materialHandlingDefaultValue += packageDetails.digital
            ? packageDetails.posting
            : 0;
        assets += packageDetails.panels;
        impressions += packageDetails.contacts;
    });
    const totalAvailability = resolvePackagesAvailability(packages);
    const currentFilterPrice = preparePriceModel(currentFilter.price);
    const totalPrice = {
        ...currentFilterPrice,
        grossPrice: price,
        fees: {
            production: productionDefaultValue,
            assetAllocation: assetAllocationDefaultValue,
            materialHandling: materialHandlingDefaultValue,
            photography: currentFilter?.price?.fees?.photography ?? 0,
        },
        totalNet: 0,
    };
    const totalPriceCalculated = getPriceValueUpdated(totalPrice);
    return {
        ...currentFilter,
        packages,
        price: totalPriceCalculated,
        assets,
        impressions,
        availability: totalAvailability,
    };
}
export function clearPackageSelection(planPackage) {
    return {
        ...planPackage,
        packages: [],
        price: null,
        assets: 0,
        impressions: 0,
        availability: null,
    };
}
