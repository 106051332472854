import { Audit } from '@workspaces/types';
import { findElementInSortedArray } from '../plan/utils/sort';
import { getAppMetadata } from '@/plan/metadata/metadata';
import AuditService from '@/services/audit/audit.service';
import { propagateAudienceDelivery } from '../plan/filter/audience/audience.helper';
import { filterAssetsFromPlan as filterAssets } from '../plan/filter/plan.helper';
import BrowserEnvironmentResolver from './environment.helper';
import { deserializePackage } from '@/plan/deserializeFilters';
import { serializePlanPackage } from '@/utils/serializePackage';
import { serializeLegend } from '@/utils/serializeLegend';
import { StorageDestination, getValueFromStorage, removeValueFromStorage, setValueToStorage, } from '@/cache/storage.helper';
import { SessionStorageKeys } from '@/constants';
import { serializeFilters } from '@/utils/serializeFilters';
/**
 *
 * @param assets Assets to be analyzed
 * @param planWithFilters Sub filters that compose the filter defined by the user
 * @returns Assets that match the filter defined by the user and intersect with the assets passed as parameter
 */
export async function filterAssetsFromPlan(assets, planWithFilters, latestDelivery, generateAuditEvent = true) {
    const iteratedFilteredAssets = await filterAssets(BrowserEnvironmentResolver.getInstance(), assets, planWithFilters, latestDelivery);
    if (generateAuditEvent) {
        const planForAudit = propagateAudienceDelivery(planWithFilters.filters, latestDelivery);
        // Audit event for registering loading of a plan
        const params = {
            id: planWithFilters.id,
            name: planWithFilters.name,
            filters: planForAudit,
        };
        await AuditService.createEvent(getAppMetadata(), BrowserEnvironmentResolver.getInstance(), {
            type: Audit.AuditEventType.PlanLoadedUsage,
            params: { id: planWithFilters.id, name: planWithFilters.name },
        });
        await AuditService.createEvent(getAppMetadata(), BrowserEnvironmentResolver.getInstance(), {
            type: Audit.AuditEventType.PlanLoaded,
            params: { ...params, delivery: latestDelivery },
        });
    }
    return iteratedFilteredAssets;
}
/**
 *
 * @param plan Plan
 * @returns Parsed plan
 */
export function deserializePlan(plan) {
    return {
        ...plan,
        id: plan.id,
        package: deserializePackage(plan.package),
    };
}
export function serializePlan(plan) {
    let planSerialized = {};
    if (plan.package) {
        if (typeof plan.package === 'string') {
            console.warn('Trying to serialize a plan that has packages filter already serialized');
        }
        else {
            planSerialized = {
                ...plan,
                package: serializePlanPackage(plan.package),
            };
        }
    }
    if (plan.legend) {
        planSerialized = {
            ...planSerialized,
            legend: serializeLegend(plan.legend),
        };
    }
    if (plan.filters) {
        if (typeof plan.filters === 'string') {
            console.warn('Trying to serialize a plan that has filters already serialized');
        }
        else {
            planSerialized = {
                ...planSerialized,
                filters: serializeFilters(plan.filters),
            };
        }
    }
    return planSerialized;
}
/**
 *
 * @param plans List of plans
 * @returns list of parsed plans
 */
export function deserializePlans(plans) {
    const parsedPlans = plans.map((plan) => {
        return deserializePlan(plan);
    });
    return parsedPlans;
}
export function getUnfilteredElements(allAssets, filteredAssets) {
    // console.log(':hourglass_flowing_sand: Getting assets not included in the filter')
    const filteredAssetsClone = [...filteredAssets];
    filteredAssetsClone.sort((a, b) => a.properties.id.localeCompare(b.properties.id));
    const allAssetsCloned = [...allAssets];
    allAssetsCloned.sort((a, b) => a.properties.id.localeCompare(b.properties.id));
    // console.log('    Assets sorted')
    filteredAssetsClone.forEach((asset) => {
        const index = findElementInSortedArray(asset.properties.id, ['properties', 'id'], allAssetsCloned, 0, allAssetsCloned.length - 1);
        if (index > 0) {
            allAssetsCloned.splice(index, 1);
        }
    });
    // console.log('    Assets filtered')
    const excludedAssetsFormatted = allAssetsCloned.map((asset) => {
        return {
            ...asset,
            properties: {
                ...asset.properties,
                excluded: true,
            },
        };
    });
    // console.log(':hourglass:️ Getting assets not included in the filter')
    return excludedAssetsFormatted;
}
export function getCountriesForCurrentPlan(filters) {
    const countriesSelected = filters.map((filter) => {
        return filter.countries;
    });
    const countries = [...new Set(countriesSelected.flat())];
    return countries;
}
export function savePlanFiltersToStorage(filters) {
    const filtersString = JSON.stringify(filters);
    setValueToStorage(StorageDestination.SessionStorage, SessionStorageKeys.DirtyPlanFilters, filtersString);
}
export function getPlanFiltersFromStorage() {
    const previousRouteRawValue = getValueFromStorage(StorageDestination.SessionStorage, SessionStorageKeys.PreviousRoute);
    let previousRoute = '';
    if (previousRouteRawValue !== null) {
        previousRoute = JSON.parse(previousRouteRawValue);
        removeValueFromStorage(StorageDestination.SessionStorage, SessionStorageKeys.PreviousRoute);
    }
    const filtersRawValue = getValueFromStorage(StorageDestination.SessionStorage, SessionStorageKeys.DirtyPlanFilters);
    if (filtersRawValue === null) {
        return { filters: null, previousRoute };
    }
    removeValueFromStorage(StorageDestination.SessionStorage, SessionStorageKeys.DirtyPlanFilters);
    return { filters: JSON.parse(filtersRawValue), previousRoute };
}
