import { FlavourLocalStorageKey, Flavours } from '../metadata/metadata.constant';
let appFlavour;
const getFlavourBasedOnLocalStorage = () => {
    const flavour = localStorage.getItem(FlavourLocalStorageKey);
    if (flavour) {
        const flavourMetadata = Flavours.filter((flavourMetadata) => {
            return flavourMetadata.flavour === flavour;
        });
        if (flavourMetadata.length === 0) {
            throw new Error(`Getting flavour from local storage. No flavour found for ${flavour} - Make sure the flavour is defined in metadata/metadata.`);
        }
        if (flavourMetadata.length > 1) {
            throw new Error(`Getting flavour from local storage. More than one flavour found for ${flavour} - Make sure the flavour is defined in only one flavour in metadata/metadata.`);
        }
        return flavourMetadata[0];
    }
    throw new Error('Getting flavour from local storage. No value found.');
};
const lookForFlavourInLocalStorage = () => {
    const url = window.location.href;
    if (url.startsWith('https://localhost')) {
        return true;
    }
    return localStorage.getItem(FlavourLocalStorageKey) !== null;
};
const lookForFlavourInUrlQueryParams = (queryParams) => {
    const shared = queryParams.get('shared');
    const sharedFormatted = shared === 'true';
    return sharedFormatted;
};
const getFlavourInUrlQueryParams = (queryParams) => {
    const shared = queryParams.get('shared');
    const sharedFormatted = shared === 'true';
    if (sharedFormatted) {
        const parsedFlavour = queryParams.get('appVersion');
        if (parsedFlavour) {
            const flavourMetadata = Flavours.filter((flavourMetadata) => {
                return (flavourMetadata.flavour === parsedFlavour ||
                    flavourMetadata.flavour_testing === parsedFlavour);
            });
            if (flavourMetadata.length === 0) {
                throw new Error(`Getting flavour from url query params. No flavour found for ${parsedFlavour} - Make sure the flavour is defined in metadata/metadata.`);
            }
            if (flavourMetadata.length > 1) {
                throw new Error(`Getting flavour from url query params. More than one flavour found for ${parsedFlavour} - Make sure the flavour is defined in only one flavour in metadata/metadata.`);
            }
            return flavourMetadata[0];
        }
        throw new Error('Getting flavour from url query params. No value found.');
    }
    throw new Error('Expected url to contain query param shared=true');
};
export const getFlavour = () => {
    if (appFlavour) {
        return appFlavour;
    }
    const url = window.location.href;
    const urlQueryParams = new URLSearchParams(url);
    if (lookForFlavourInUrlQueryParams(urlQueryParams)) {
        appFlavour = getFlavourInUrlQueryParams(urlQueryParams);
        return appFlavour;
    }
    if (lookForFlavourInLocalStorage()) {
        appFlavour = getFlavourBasedOnLocalStorage();
        return appFlavour;
    }
    const accessUrl = window.location.href;
    const flavour = Flavours.filter((flavour) => {
        const url = flavour.app_config.front_url.find((frontUrl) => {
            const frontUrlAsPattern = new RegExp(frontUrl);
            const comparisom = frontUrlAsPattern.test(accessUrl);
            return comparisom;
        });
        return url !== undefined;
    });
    if (flavour.length === 0) {
        throw new Error('No flavour found - Make sure the url is defined in a flavour in metadata/metadata.');
    }
    if (flavour.length > 1) {
        throw new Error('More than one flavour found - Make sure the url is defined in only one flavour in metadata/metadata.');
    }
    appFlavour = flavour[0];
    return appFlavour;
};
