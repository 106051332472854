import { getCacheInforForModel } from '@/plan/metadata/metadata.helper';
import { sleep } from '@/helpers/common.helper';
export function getKeyValue(countryId, key) {
    return `${countryId}_${key}`;
}
async function getLatestVersion(dbName) {
    const databases = await indexedDB.databases();
    const database = databases.find((db) => db.name === dbName);
    if (!database) {
        return null;
    }
    return database.version ?? 1;
}
async function openDatabase(dbName, store, version = null) {
    let latestVersion = version;
    if (!latestVersion) {
        for (let i = 0; i < 5; i++) {
            latestVersion = await getLatestVersion(dbName);
            if (latestVersion !== null) {
                break;
            }
            await sleep(200);
        }
        if (latestVersion === null) {
            throw new Error('Database not found');
        }
    }
    return new Promise((resolve, reject) => {
        const request = indexedDB.open(dbName, latestVersion);
        request.onerror = () => reject(request.error);
        request.onsuccess = () => resolve(request.result);
        request.onupgradeneeded = () => {
            const db = request.result;
            db.createObjectStore(store);
        };
    });
}
export async function putToIndexDb(dbName, version, storeName, key, value) {
    const db = await openDatabase(dbName, storeName);
    const transaction = db.transaction(storeName, 'readwrite');
    const store = transaction.objectStore(storeName);
    // Convertir los datos a una cadena JSON y luego a bytes
    const encoder = new TextEncoder();
    const contentBytes = encoder.encode(JSON.stringify(value));
    // Guardar los bytes en el almacén de objetos
    const request = store.put(contentBytes, key);
    await new Promise((resolve, reject) => {
        request.onsuccess = () => resolve(request.result);
        request.onerror = () => reject(request.error);
    });
    db.close();
}
export async function getFromIndexDb(dbName, version, storeName, key) {
    const db = await openDatabase(dbName, storeName);
    const transaction = db.transaction(storeName, 'readonly');
    const store = transaction.objectStore(storeName);
    const request = store.get(key);
    const contentBytes = await new Promise((resolve, reject) => {
        request.onsuccess = () => resolve(request.result);
        request.onerror = () => reject(request.error);
    });
    if (!contentBytes) {
        return null;
    }
    db.close();
    const decoder = new TextDecoder();
    const assetsJson = decoder.decode(contentBytes);
    const assets = JSON.parse(assetsJson);
    return assets;
}
export async function prepareIndexDB(meta) {
    const metaModelsWithDatabases = Object.values(meta.data_model).filter((model) => {
        return model.cache !== undefined;
    });
    const metaModelDatabasesMap = new Map();
    metaModelsWithDatabases.forEach((model) => {
        const { dbName, version, store } = getCacheInforForModel(model);
        const db = metaModelDatabasesMap.get(dbName);
        if (db) {
            db.push({ version, store });
        }
        else {
            metaModelDatabasesMap.set(dbName, [{ version, store }]);
        }
    });
    for (const [dbName, stores] of metaModelDatabasesMap.entries()) {
        stores.sort((a, b) => a.version - b.version);
        const latestVersion = await getLatestVersion(dbName);
        if (latestVersion) {
            for (let i = 0; i < latestVersion; i++) {
                stores.shift();
            }
        }
        stores.forEach(async (store) => {
            console.debug('Creating store', store);
            const db = await openDatabase(dbName, store.store, store.version);
            db.close();
        });
    }
}
