import { executeFetch } from '@carto/ps-services';
function getGroupsValue(value) {
    if (value === undefined) {
        return undefined;
    }
    return value.split(', ');
}
export async function getCartoMeInfo(environment, cartoToken) {
    const credentials = { accessToken: cartoToken };
    let userInfo = {
        groups: (getGroupsValue(process.env.VUE_APP_TESTING_USER_GROUPS) ??
            []),
        email: 'unknown',
        name: 'unknown',
    };
    if (environment.isTesting()) {
        console.debug('😈 Testing mode enabled - returning fake cartoMe content', userInfo);
        return userInfo;
    }
    try {
        userInfo = await executeFetch({
            abortController: new AbortController().signal,
            credentials,
            url: 'https://accounts.app.carto.com/users/me',
        });
    }
    catch (error) {
        const msg = 'User not recognized by Carto';
        console.error(msg, error);
        throw new Error(msg);
    }
    return userInfo;
}
