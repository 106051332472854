import { filterAssetsByAudience, propagateAudienceDelivery, } from './audience/audience.helper';
import { filterAssetsByExcludedGeoboundary, filterAssetsByGeoboundary, filterAssetsByGeoboundaryRegion18, unionOfAssets, } from './geoboundary.helper';
import { setExcludedPropertyToAssetsBySubFilter, filterAssetsByInventory, filterAssetsByOptimizeDistribution, addIncludedAssetsFromExcludeLayer, filterAssetsByInventoryOverwrittingAssets, } from './asset.helper';
import { filterAssetsByMediaType, filterAssetsByDigital, } from './mediatype.helper';
import { filterAssetsByPackages } from './packages.helper';
import { filterAssetsByCountry } from './country.helper';
import { filterAssetsByProximity, getFilterByPoisParamsRequestFromPoisfilter, } from './pois.helper';
import { getAppMetadata } from '../metadata/metadata';
import { getOptimizeDistributionRegions, hasOptimizeDistributionFilter, } from './geographicalDistribution.helper';
import { filterAssetsByCustomGeoboundaryDatasets, filterAssetsByExcludedCustomGeoboundaryDatasets, } from './custom-geoboundary-dataset.helper';
import { isPackagagesFeatureEnabled, isAudiencesQuintileEnabled, } from '../metadata/metadata.helper';
import { filterAssetsByAudiencesQuintile } from './audiences-quintile.helper';
function getAssetsUnionCombination(assets1, assets2) {
    if (assets1.length === 0) {
        return assets2;
    }
    if (assets2.length === 0) {
        return assets1;
    }
    const allAssets = [...assets1, ...assets2];
    const assetsAfterUnion = [
        ...new Map(allAssets.map((item) => [item.properties.id, item])).values(),
    ];
    return assetsAfterUnion;
}
/**
 * Get the resultant assets from applying a sub filter to all the assets
 * @param assets Assets to be analyzed
 * @param subFilter Porperties of the sub filter to be applied
 * @returns Assets that match the sub filter and intersect with the assets passed as parameter
 */
async function filterAssetsFromSubFilter(environment, assets, subFilter, latestDelivery) {
    const metadata = getAppMetadata();
    let assetsFiltered = [...assets];
    let appliedFilterAtAnyStage = false;
    let assetsAudience = [];
    // Country filter
    if (metadata.plan_filters.widget_countries.enable) {
        const t0 = new Date().getTime();
        const countriesPartialFilterResult = filterAssetsByCountry(assetsFiltered, subFilter.countries);
        console.debug(`           🔍 Countries || IN: ${assetsFiltered.length}  OUT: ${countriesPartialFilterResult.assets.length}  TIME: ${new Date().getTime() - t0}`);
        assetsFiltered = countriesPartialFilterResult.assets;
        appliedFilterAtAnyStage =
            countriesPartialFilterResult.filterApplied || appliedFilterAtAnyStage;
    }
    // Audience-quintile filter
    const audiencesQuintileFilterEnabled = isAudiencesQuintileEnabled(metadata);
    if (audiencesQuintileFilterEnabled) {
        const t0 = new Date().getTime();
        const audienceQuintilePartialResult = await filterAssetsByAudiencesQuintile(metadata, environment, assetsFiltered, subFilter);
        console.debug(`     🔍 Audiences Quintile || IN: ${assetsFiltered.length}  OUT: ${audienceQuintilePartialResult.assets.length}  TIME: ${new Date().getTime() - t0}`);
        assetsFiltered = audienceQuintilePartialResult.assets;
        appliedFilterAtAnyStage =
            audienceQuintilePartialResult.filterApplied || appliedFilterAtAnyStage;
    }
    // Geoboundary filter
    if (metadata.plan_filters.widget_geoboundaries.enable) {
        const t0 = new Date().getTime();
        let appliedGeoboundaryFilterAtAnyStage = false;
        const assetsCountAtStart = assetsFiltered.length;
        // Included filters
        const geoboundaryPartialFilterResult = filterAssetsByGeoboundary(assetsFiltered, subFilter.geoboundaries, subFilter.polygon_geom);
        console.debug(`            Included regions, resultant assets: ${geoboundaryPartialFilterResult.assets.length}`);
        // Region 18 filter: filter by custom geoboundary external file
        const geoboundaryPartialFilterResultRegion18 = await filterAssetsByGeoboundaryRegion18(environment, metadata, assetsFiltered, subFilter.geoboundaries);
        appliedGeoboundaryFilterAtAnyStage =
            geoboundaryPartialFilterResult.filterApplied ||
                geoboundaryPartialFilterResultRegion18.filterApplied ||
                appliedGeoboundaryFilterAtAnyStage;
        let partialUnionOfAssetsFiltered = [];
        if (!appliedGeoboundaryFilterAtAnyStage &&
            !geoboundaryPartialFilterResultRegion18.filterApplied) {
            console.debug('            No region 18 geoboundary filter applied');
        }
        else {
            partialUnionOfAssetsFiltered = unionOfAssets(geoboundaryPartialFilterResult.assets, geoboundaryPartialFilterResultRegion18.assets);
        }
        // Custom geoboundaries datasets
        const customGeoboundaryDatasetsPartialFilterResult = await filterAssetsByCustomGeoboundaryDatasets(environment, assetsFiltered, subFilter);
        console.debug(`            Included custom geoboundaries dataset, resultant assets: ${customGeoboundaryDatasetsPartialFilterResult.assets.length}`);
        if (!appliedGeoboundaryFilterAtAnyStage &&
            !customGeoboundaryDatasetsPartialFilterResult.filterApplied) {
            console.debug('            No included geoboundary filter applied');
        }
        else {
            assetsFiltered = unionOfAssets(partialUnionOfAssetsFiltered, customGeoboundaryDatasetsPartialFilterResult.assets);
        }
        console.debug(`            Included assets: ${assetsFiltered.length}`);
        appliedGeoboundaryFilterAtAnyStage =
            customGeoboundaryDatasetsPartialFilterResult.filterApplied ||
                appliedGeoboundaryFilterAtAnyStage;
        // Excluded custom geoboundaries datasets
        const geoboundaryPartialFilterResult4 = await filterAssetsByExcludedCustomGeoboundaryDatasets(environment, assetsFiltered, subFilter);
        console.debug(`            Excluded custom geoboundaries dataset, resultant assets: ${geoboundaryPartialFilterResult4.assets.length}`);
        assetsFiltered = geoboundaryPartialFilterResult4.assets;
        appliedGeoboundaryFilterAtAnyStage =
            geoboundaryPartialFilterResult4.filterApplied ||
                appliedGeoboundaryFilterAtAnyStage;
        // Excluded filters
        const geoboundaryPartialFilterResult3 = filterAssetsByExcludedGeoboundary(assetsFiltered, subFilter.geoboundaries);
        console.debug(`            Excluded regions, resultant assets: ${geoboundaryPartialFilterResult3.assets.length}`);
        assetsFiltered = geoboundaryPartialFilterResult3.assets;
        appliedGeoboundaryFilterAtAnyStage =
            geoboundaryPartialFilterResult3.filterApplied ||
                appliedGeoboundaryFilterAtAnyStage;
        // const excludedAssets = unionOfAssets(
        //   geoboundaryPartialFilterResult4.assets,
        //   geoboundaryPartialFilterResult3.assets
        // )
        // console.debug(`      Excluded assets: ${excludedAssets.length}`)
        // assetsFiltered = removeExcludedAssets(includedAssets, excludedAssets)
        // console.debug(`      Assets after removing excluded assets: ${assetsFiltered.length}`)
        appliedFilterAtAnyStage =
            appliedFilterAtAnyStage || appliedGeoboundaryFilterAtAnyStage;
        console.debug(`           🔍 Geoboundaries || IN: ${assetsCountAtStart}  OUT: ${assetsFiltered.length}  TIME: ${new Date().getTime() - t0}`);
    }
    // Audience filter
    if (metadata.plan_filters.widget_audiences.enable) {
        const t0 = new Date().getTime();
        const audiencePartialResult = await filterAssetsByAudience(environment, assetsFiltered, subFilter, latestDelivery);
        console.debug(`           🔍 Audiences || IN: ${assetsFiltered.length}  OUT: ${audiencePartialResult.assets.length}  TIME: ${new Date().getTime() - t0}`);
        assetsFiltered = audiencePartialResult.assets;
        appliedFilterAtAnyStage =
            audiencePartialResult.filterApplied || appliedFilterAtAnyStage;
        assetsAudience = audiencePartialResult.assetsAudience;
    }
    // Digital filter
    if (metadata.plan_filters.widget_media_type.enable &&
        metadata.plan_filters.widget_media_type.filter_digital) {
        const t0 = new Date().getTime();
        const digitalPartialResult = filterAssetsByDigital(assetsFiltered, subFilter);
        console.debug(`           🔍 Digital || IN: ${assetsFiltered.length}  OUT: ${digitalPartialResult.assets.length}  TIME: ${new Date().getTime() - t0}`);
        assetsFiltered = digitalPartialResult.assets;
        appliedFilterAtAnyStage =
            digitalPartialResult.filterApplied || appliedFilterAtAnyStage;
    }
    // Media types filter
    if (metadata.plan_filters.widget_media_type.enable) {
        const t0 = new Date().getTime();
        const mediaTypePartialResult = filterAssetsByMediaType(assetsFiltered, subFilter);
        console.debug(`           🔍 Media Type || IN: ${assetsFiltered.length}  OUT: ${mediaTypePartialResult.assets.length}  TIME: ${new Date().getTime() - t0}`);
        assetsFiltered = mediaTypePartialResult.assets;
        appliedFilterAtAnyStage =
            mediaTypePartialResult.filterApplied || appliedFilterAtAnyStage;
    }
    // Packages filter
    if (metadata.plan_filters.widget_packages.enable) {
        const t0 = new Date().getTime();
        const packagesPartialResult = filterAssetsByPackages(assetsFiltered, subFilter);
        console.debug(`           🔍 Packages || IN: ${assetsFiltered.length}  OUT: ${packagesPartialResult.assets.length}  TIME: ${new Date().getTime() - t0}`);
        assetsFiltered = packagesPartialResult.assets;
        appliedFilterAtAnyStage =
            packagesPartialResult.filterApplied || appliedFilterAtAnyStage;
    }
    // Proximity filter
    if (metadata.plan_filters.widget_proximity.enable) {
        const t0 = new Date().getTime();
        const proximityPartialResult = await filterAssetsByProximity(environment, assetsFiltered, subFilter);
        const incomingAssetsForPois = assetsFiltered;
        assetsFiltered = proximityPartialResult.assets;
        appliedFilterAtAnyStage =
            proximityPartialResult.filterApplied || appliedFilterAtAnyStage;
        if (proximityPartialResult.filterApplied) {
            const poisFilter = getFilterByPoisParamsRequestFromPoisfilter(subFilter.proximity);
            if (poisFilter.max_assets_per_poi > 0) {
                const proximityPartialResult = await filterAssetsByProximity(environment, assetsFiltered, subFilter, false);
                assetsFiltered = proximityPartialResult.assets;
                appliedFilterAtAnyStage =
                    proximityPartialResult.filterApplied || appliedFilterAtAnyStage;
            }
        }
        console.debug(`           🔍 Proximity || IN: ${incomingAssetsForPois.length}  OUT: ${proximityPartialResult.assets.length}  TIME: ${new Date().getTime() - t0}`);
    }
    // Inventory assets ids filter
    if (metadata.plan_filters.widget_assets.enable) {
        const t0 = new Date().getTime();
        let inventoryPartialResult;
        if (!subFilter?.panels.ids) {
            inventoryPartialResult = { assets: assetsFiltered, filterApplied: false };
        }
        else {
            if (isPackagagesFeatureEnabled(metadata)) {
                // If package feature is enable, means FI flavour, we need to set only the assets that are provided in the uploaded file
                inventoryPartialResult = filterAssetsByInventoryOverwrittingAssets(assets, subFilter);
            }
            else {
                inventoryPartialResult = filterAssetsByInventory(assets, assetsFiltered, subFilter);
            }
        }
        console.debug(`           🔍 Inventory || IN: ${assetsFiltered.length}  OUT: ${inventoryPartialResult.assets.length}  TIME: ${new Date().getTime() - t0}`);
        assetsFiltered = inventoryPartialResult.assets;
        appliedFilterAtAnyStage =
            inventoryPartialResult.filterApplied || appliedFilterAtAnyStage;
    }
    // Included assets via map
    let t0 = new Date().getTime();
    const includedAssetsPartialResult = addIncludedAssetsFromExcludeLayer(assets, assetsFiltered, subFilter.assets_included);
    console.debug(`           🔍 Included || IN: ${assetsFiltered.length}  OUT: ${includedAssetsPartialResult.assets.length}  TIME: ${new Date().getTime() - t0}`);
    assetsFiltered = includedAssetsPartialResult.assets;
    appliedFilterAtAnyStage =
        includedAssetsPartialResult.filterApplied || appliedFilterAtAnyStage;
    // Exclude assets
    t0 = new Date().getTime();
    const assetsFilteredExcluded = setExcludedPropertyToAssetsBySubFilter(assets, assetsFiltered, [...subFilter.assets_excluded]);
    console.debug(`           🔍 Excluded || IN: ${assetsFiltered.length}  OUT: ${assetsFilteredExcluded.length}  TIME: ${new Date().getTime() - t0}`);
    assetsFiltered = assetsFilteredExcluded;
    appliedFilterAtAnyStage =
        appliedFilterAtAnyStage || subFilter.assets_excluded.length > 0;
    return {
        assets: assetsFiltered,
        filterApplied: appliedFilterAtAnyStage,
        assetsAudience,
    };
}
/**
 *
 * @param assets Assets to be analyzed
 * @param planWithFilters Sub filters that compose the filter defined by the user
 * @returns Assets that match the filter defined by the user and intersect with the assets passed as parameter
 */
export async function filterAssetsFromPlan(environment, assets, planWithFilters, latestDelivery) {
    const metadata = getAppMetadata();
    let assetsFiltered = [];
    let filterAppliedAtAnySubFilter = false;
    const planFilterUpdated = propagateAudienceDelivery(planWithFilters.filters);
    const audiencesAssetIdAndIndex = [];
    let num = 0;
    for (const subFilter of planFilterUpdated) {
        console.debug(`🟪 Plan: 🔍 Subfilter: ${num}`);
        const { assets: assetsFilteredFromSubPlan, filterApplied, assetsAudience, } = await filterAssetsFromSubFilter(environment, assets, subFilter, latestDelivery);
        filterAppliedAtAnySubFilter = filterAppliedAtAnySubFilter || filterApplied;
        if (filterApplied) {
            assetsFiltered = getAssetsUnionCombination(assetsFiltered, assetsFilteredFromSubPlan);
        }
        if (assetsAudience) {
            audiencesAssetIdAndIndex.push(assetsAudience);
        }
        num++;
    }
    const iteratedFilteredAssets = filterAppliedAtAnySubFilter
        ? assetsFiltered
        : assets;
    // OPTIMIZE DISTRIBUTION
    // Filter optimize distribution to the complete set of filtered assets
    if (metadata.plan_filters.widget_optimize_distribution.enable &&
        hasOptimizeDistributionFilter(planFilterUpdated)) {
        console.debug('⏳ - Filtering assets from geographic optimization starting ...');
        const geographicalDistributionRegions = await getOptimizeDistributionRegions(metadata, environment, planFilterUpdated);
        const { assets: optimizeDistributionResults } = await filterAssetsByOptimizeDistribution(metadata, environment, iteratedFilteredAssets, planFilterUpdated[0], geographicalDistributionRegions, audiencesAssetIdAndIndex);
        console.debug('  ⏳ - Filtering assets from geographic optimization Ended');
        return optimizeDistributionResults;
    }
    return iteratedFilteredAssets;
}
/**
 *
 * @param assets Assets to be analyzed
 * @param excluded List of assets ids to be excluded
 * @returns Assets with the excluded property set to true if the asset id is in the excluded list
 */
export async function excludeAssetsFromPlan(assets, excluded) {
    return assets.map((asset) => {
        asset.properties.excluded = false;
        if (excluded.includes(asset.properties.id)) {
            asset.properties.excluded = true;
        }
        return asset;
    });
}
