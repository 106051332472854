import { Package } from '@workspaces/types';
import UserPermissionManager from '@/helpers/permissions/permissions.manager';
import { getColorForAvailability } from '../metadata/metadata.helper';
export function isStateUpdateable(currentState, newState, plan) {
    console.debug(`Checking if state package state can be updated from ${currentState} to ${newState}`);
    const userPermissionManager = UserPermissionManager.getInstance();
    switch (currentState) {
        case Package.State.Draft:
            return (userPermissionManager.canBookPlanPackage(plan) &&
                newState === Package.State.BookingRequest);
        case Package.State.BookingRequest:
            return (userPermissionManager.canConfirmBookingPlanPackageByAdmin(plan) &&
                (newState === Package.State.BookingConfirmedByAdmin ||
                    newState === Package.State.Cancelled));
        case Package.State.BookingConfirmedByAdmin: {
            const canAgencyContinueManagingPlan = userPermissionManager.canConfirmBookingPlanPackageByAgency(plan);
            if (canAgencyContinueManagingPlan) {
                return (newState === Package.State.BookingConfirmedByAgency ||
                    newState === Package.State.ReBooking ||
                    newState === Package.State.Cancelled);
            }
            else {
                return newState === Package.State.Expired;
            }
        }
        case Package.State.BookingConfirmedByAgency:
            return (userPermissionManager.canFinalizeConfirmBookingPlanPackageByAdmin(plan) && newState === Package.State.Booked);
        case Package.State.Booked:
            return (userPermissionManager.canCancelPlanPackageByAdmin(plan) &&
                newState === Package.State.Cancelled);
        case Package.State.Rejected:
            return false;
        default:
            console.warn(`Trying to update package state, but state ${currentState} is not recognized`);
            return false;
    }
}
export function getPackageStateForVisualAppearance(state) {
    return state;
}
export function getForegroundColorForAvailability(availability, style) {
    return availability !== undefined && availability !== null
        ? style.colorHigh
        : style.colorFeedError;
}
export function getAvailabilityColor(meta, availability) {
    let availabilityFormatted = availability;
    if (!availabilityFormatted) {
        availabilityFormatted = 0;
    }
    const color = getColorForAvailability(meta, availabilityFormatted);
    return color;
}
