import { getAppMetadata } from '../metadata/metadata';
export function filterAssetsByMediaType(assets, filter) {
    if (filter === undefined || filter === null) {
        return { assets, filterApplied: false };
    }
    // list of media types is empty
    const metaTypes = getAppMetadata().plan_filters.widget_media_type.types;
    const filterEmpty = Object.entries(metaTypes)
        .map(([key]) => {
        const metaTypeKey = key;
        return filter.types[metaTypeKey];
    })
        .flat();
    if (filterEmpty.length === 0) {
        return { assets, filterApplied: false };
    }
    // filter
    let assetsFiltered = assets;
    for (const [key, field] of Object.entries(metaTypes)) {
        const metaTypeKey = key;
        const elements = filter.types[metaTypeKey];
        if (elements && Array.isArray(elements) && elements.length === 0) {
            continue;
        }
        const includedFilterIds = elements
            .filter((element) => !element.exclude)
            .map((element) => element.id)
            .flat();
        let includedAssets = [];
        if (includedFilterIds.length) {
            includedAssets = assetsFiltered.filter((asset) => {
                const { properties } = asset;
                const assetType = properties[field];
                return includedFilterIds.includes(assetType);
            });
        }
        else {
            includedAssets = assetsFiltered;
        }
        const excludedFilterIds = elements
            .filter((element) => element.exclude)
            .map((element) => element.id)
            .flat();
        let excludedAssets = [];
        if (excludedFilterIds.length) {
            excludedAssets = includedAssets.filter((asset) => {
                const { properties } = asset;
                const assetType = properties[field];
                return !excludedFilterIds.includes(assetType);
            });
        }
        else {
            excludedAssets = includedAssets;
        }
        assetsFiltered = [...excludedAssets];
    }
    // end
    return { assets: assetsFiltered, filterApplied: true };
}
export function filterAssetsByDigital(assets, filter) {
    if (filter === undefined || filter === null) {
        return { assets, filterApplied: false };
    }
    if (filter.isDigital === undefined) {
        return { assets, filterApplied: false };
    }
    // filter
    const assetsFiltered = assets.filter((asset) => {
        return asset.properties.is_digital === filter.isDigital;
    });
    // end
    return { assets: assetsFiltered, filterApplied: true };
}
