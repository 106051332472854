import { Package } from '@workspaces/types';
export function filterAssetsByPackages(assets, filter) {
    if (!filter.package || filter.package.length === 0) {
        return { assets, filterApplied: false };
    }
    const filterPackagesAsMap = new Map(filter.package.map((p) => [p, true]));
    const assetsFiltered = assets.filter((asset) => {
        const { properties } = asset;
        if (!properties.packages) {
            return false;
        }
        if (properties.packages.length === 0) {
            return false;
        }
        const anyAssetPackageInFilter = properties.packages.some((p) => filterPackagesAsMap.has(p));
        if (filter.package && anyAssetPackageInFilter) {
            return true;
        }
        return false;
    });
    return { assets: assetsFiltered, filterApplied: true };
}
export function filterPackages(allPackages, filter) {
    let partialPackages = allPackages;
    if (filter.priceRange) {
        const [min, max] = filter.priceRange;
        partialPackages = allPackages.filter((p) => p.gross_price >= min && p.gross_price <= max);
    }
    if (filter.panelType) {
        const { digital, l1, l2, l3, l4 } = filter.panelType;
        if (digital !== undefined) {
            partialPackages = partialPackages.filter((p) => p.digital === digital);
        }
        if (l1.length) {
            partialPackages = partialPackages.filter((p) => p.l1 ? l1.includes(p.l1) : false);
        }
        if (l2.length) {
            partialPackages = partialPackages.filter((p) => {
                if (p.l2?.length) {
                    return l2.some((l2) => (p.l2 ? p.l2.includes(l2) : false));
                }
                return false;
            });
        }
        if (l3.length) {
            partialPackages = partialPackages.filter((p) => {
                if (p.l3?.length) {
                    const hasValue = l3.some((l3) => (p.l3 ? p.l3.includes(l3) : false));
                    return hasValue;
                }
                return false;
            });
        }
        if (l4.length) {
            partialPackages = partialPackages.filter((p) => {
                if (p.l4?.length) {
                    const hasValue = l4.some((l4) => (p.l4 ? p.l4.includes(l4) : false));
                    return hasValue;
                }
                return false;
            });
        }
    }
    return partialPackages;
}
export function getExpirationForAgencyBookingConfirmation(bookedConfirmByAdminAt, expirationPeriodTimeInMilis) {
    return new Date(bookedConfirmByAdminAt.getTime() + expirationPeriodTimeInMilis);
}
export function getExpirationForAgencyCancellation(planStartDate, expirationPeriodTimeInMilis) {
    const now = new Date();
    const nowAsMillis = now.getTime();
    const planStartDateNormalized = new Date(planStartDate);
    planStartDateNormalized.setHours(0, 0, 0, 0);
    const expirationForCancellation = new Date(planStartDateNormalized.getTime() - expirationPeriodTimeInMilis);
    if (expirationForCancellation.getTime() < nowAsMillis) {
        return now;
    }
    return expirationForCancellation;
}
export function isPlanPackageExpiredForNoAgencyConfirmation(planPackage) {
    const packagePlan = planPackage;
    const state = packagePlan.state;
    if (state !== Package.State.BookingConfirmedByAdmin) {
        return false;
    }
    if (packagePlan.expirationForAgencyBookingConfirmation === undefined) {
        throw new Error('Trying to verify if plan is expired, but expiration is undefined when state is BookingConfirmedByAdmin');
    }
    const nowInMillis = new Date().getTime();
    const expirationInMillis = packagePlan.expirationForAgencyBookingConfirmation.getTime();
    return nowInMillis > expirationInMillis;
}
