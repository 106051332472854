export var State;
(function (State) {
    State["Draft"] = "DRAFT";
    State["BookingRequest"] = "BOOKING_REQUEST";
    State["BookingConfirmedByAdmin"] = "BOOKING_CONFIRMED_BY_ADMIN";
    State["BookingConfirmedByAgency"] = "BOOKING_CONFIRMED_BY_AGENCY";
    State["ReBooking"] = "RE_BOOK";
    State["Expired"] = "EXPIRED";
    State["Booked"] = "BOOKED";
    State["Cancelled"] = "CANCELLED";
    State["Rejected"] = "REJECTED";
})(State || (State = {}));
