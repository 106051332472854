export function escapeString(str, lowercase = false) {
    const rawStr = lowercase ? str.toLocaleLowerCase() : str;
    return rawStr
        .trim()
        .replaceAll("'", "\\'")
        .replaceAll('`', '\\`')
        .replaceAll('´', "\\'");
}
function isStringStartingAndEndingWith(escapedChars, str) {
    return escapedChars.some((char) => str.startsWith(char) && str.endsWith(char));
}
export function removeWordDelimiters(entry) {
    const escapedChars = ['"'];
    const formattedEntry = entry.map((e) => {
        if (isStringStartingAndEndingWith(escapedChars, e)) {
            return e.slice(1, e.length - 1);
        }
        return e;
    });
    return formattedEntry;
}
