import { PlanService } from '@workspaces/services';
import { getInstance as getAuth0ServiceIntance } from '@/auth/index';
import { deserializePlan, deserializePlans } from '@/helpers/plan.helper';
import { isPackagagesFeatureEnabled } from '@/plan/metadata/metadata.helper';
import { getUserAgencyId } from '@/helpers/agency.helper';
import { ADMIN_AGENCY } from '@/constants';
export default {
    // TODO: Ver si podemos borrar getPlans
    async getPlans(meta, environment) {
        return await PlanService.getPlans(meta, environment);
    },
    async getPlan(meta, environment, planId) {
        const plan = await PlanService.getPlan(meta, environment, planId);
        return deserializePlan(plan);
    },
    async createPlan(meta, environment, plan) {
        const auth0Instance = getAuth0ServiceIntance();
        const userId = auth0Instance.getUser();
        plan.created_by = userId;
        if (isPackagagesFeatureEnabled(meta) && !plan.agency) {
            const carto3Token = auth0Instance.getAccessTokenCartoV3();
            const agencyId = await getUserAgencyId(meta, environment, carto3Token);
            plan.agency = agencyId;
        }
        const planId = await PlanService.createPlan(meta, environment, plan);
        return planId;
    },
    async updatePlan(meta, environment, planId, plan) {
        return await PlanService.updatePlan(meta, environment, planId, plan);
    },
    async deletePlan(meta, environment, planId) {
        return await PlanService.deletePlan(meta, environment, planId);
    },
    async getPublicPlans(meta, environment) {
        let plans = [];
        if (isPackagagesFeatureEnabled(meta)) {
            const auth0Instance = getAuth0ServiceIntance();
            const carto3Token = auth0Instance.getAccessTokenCartoV3();
            const agencyId = await getUserAgencyId(meta, environment, carto3Token, true);
            if (!agencyId) {
                // this is not going to happen, but we need to handle the case
                plans = [];
            }
            else {
                if (agencyId === ADMIN_AGENCY) {
                    plans = await PlanService.getPlans(meta, environment);
                }
                else {
                    plans = await PlanService.getPublicPlans(meta, environment, agencyId);
                }
            }
        }
        else {
            plans = await PlanService.getPublicPlans(meta, environment);
        }
        return deserializePlans(plans);
    },
    async getPlansByUserId(meta, environment, userId) {
        let plans = await PlanService.getPlansByUserId(meta, environment, userId);
        plans = deserializePlans(plans);
        return plans;
    },
    async getPlanByAidaId(meta, environment, aidaId) {
        const plan = await PlanService.getPlanByAidaId(meta, environment, aidaId);
        return plan ? deserializePlan(plan) : null;
    },
    async getPlansByCustomGeoboundaryId(meta, environment, customGeoboundaryId) {
        let plans = await PlanService.getPlansByCustomGeoboundaryId(meta, environment, customGeoboundaryId);
        plans = deserializePlans(plans);
        return plans;
    },
    async getPlansByCustomPoiId(meta, environment, customPoiId) {
        let plans = await PlanService.getPlansByCustomPoiId(meta, environment, customPoiId);
        plans = deserializePlans(plans);
        return plans;
    },
    async initFirebase(meta, environment) {
        await PlanService.initFirebase(meta, environment);
    },
};
