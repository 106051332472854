export var MediaTypeFeatureVersion;
(function (MediaTypeFeatureVersion) {
    MediaTypeFeatureVersion[MediaTypeFeatureVersion["V1"] = 1] = "V1";
    MediaTypeFeatureVersion[MediaTypeFeatureVersion["V2"] = 2] = "V2";
})(MediaTypeFeatureVersion || (MediaTypeFeatureVersion = {}));
export const LEVELS = [
    'l1panelclass',
    'l2product',
    'l3subproduct',
    'l4',
    'l5',
    'l6',
    'l7',
    'l8',
    'l9',
];
