export function convertPointsToGeoJsonPoint(points) {
    return {
        type: 'FeatureCollection',
        features: points.map((point) => ({
            type: 'Feature',
            geometry: JSON.parse(point.geom),
            properties: {
                name: point.name,
                address: point.address,
            },
        })),
    };
}
