import { getAppMetadata } from '../metadata/metadata';
import GeoboundaryService from '@/services/geoboundary.service';
import GeometryService from '@/services/geometry.service';
import { isCustomGeoboundaryDatasetEnabled } from '../metadata/metadata.helper';
import { cacheCustomGeoboundaryDatasetsInFilter } from '../filter/custom-geoboundary-dataset.helper';
export function getFormattedNameForZipCodeFromFile(zipCodeName) {
    if (!zipCodeName) {
        return zipCodeName;
    }
    return zipCodeName.trim().toLowerCase();
}
export function getReducedAndFormattedNameForZipCodeFromFile(zipCodeName) {
    if (!zipCodeName) {
        return zipCodeName;
    }
    return zipCodeName.trim().toLowerCase().replaceAll(' ', '');
}
async function getGeomertiesForZipCodes(environment, region15Values) {
    const zipCodesNames = new Set();
    region15Values.forEach((region15Value) => {
        region15Value.forEach((zipCode) => {
            zipCode.entities?.forEach((entity) => {
                zipCodesNames.add(getFormattedNameForZipCodeFromFile(entity));
            });
        });
    });
    if (zipCodesNames.size === 0) {
        return [];
    }
    const metadata = getAppMetadata();
    const queryParams = {
        names: Array.from(zipCodesNames),
        type_id: 7,
    };
    const geometries = await GeoboundaryService.getGeometryByNames(metadata, environment, queryParams);
    return geometries;
}
async function getGeometriesForRegion18(environment, region18Values) {
    const region18Ids = new Set();
    region18Values.forEach((region18Value) => {
        region18Value.forEach((fileInfo) => {
            region18Ids.add(fileInfo.id);
        });
    });
    if (region18Ids.size === 0) {
        return [];
    }
    const region18IdsStr = Array.from(region18Ids).map((id) => id.toString());
    const geoms = await GeometryService.getGeometriesCustomFileGeometries(getAppMetadata(), environment, region18IdsStr);
    return geoms;
}
function mergeSubFiltersGeoboundaries(filter) {
    const fusionGeoboundaries = {
        region_1: [],
        region_2: [],
        region_3: [],
        region_4: [],
        region_5: [],
        region_6: [],
        region_7: [],
        region_8: [],
        region_9: [],
        region_10: [],
        region_11: [],
        region_12: [],
        region_13: [],
        region_14: [],
        region_15: [],
    };
    filter.forEach((subfilter) => {
        const { geoboundaries } = subfilter;
        let geoboundaryKey;
        for (geoboundaryKey in geoboundaries) {
            const beforeGeoboundaries = fusionGeoboundaries[geoboundaryKey] || [];
            const newGeoboundaries = geoboundaries[geoboundaryKey] || [];
            const uniqueGeoboundaries = new Set([
                ...beforeGeoboundaries,
                ...newGeoboundaries,
            ]);
            fusionGeoboundaries[geoboundaryKey] = [...uniqueGeoboundaries];
        }
    });
    return fusionGeoboundaries;
}
// eslint-disable-next-line @typescript-eslint/no-explicit-any
function isGeoboundaryFront(geoboundary) {
    return (geoboundary.has_geometry !== undefined &&
        geoboundary.id !== undefined &&
        geoboundary.type_id !== undefined);
}
function isArrayOfGeoboundaryFront(
// eslint-disable-next-line @typescript-eslint/no-explicit-any
geoboundaries) {
    const anyNotComplyValue = geoboundaries.some((geoboundary) => !isGeoboundaryFront(geoboundary));
    return !anyNotComplyValue;
}
function geoboundariesGroupedByTypeId(geoboundaries) {
    const geoboundariesGroupedByTypeId = [];
    geoboundaries.forEach((geoboundary) => {
        const geoboundaryGroup = geoboundariesGroupedByTypeId.find((geoboundaryGroup) => geoboundaryGroup.type_id === geoboundary.type_id);
        if (geoboundaryGroup) {
            geoboundaryGroup.ids.push(geoboundary.id);
        }
        else {
            geoboundariesGroupedByTypeId.push({
                type_id: geoboundary.type_id,
                ids: [geoboundary.id],
            });
        }
    });
    return geoboundariesGroupedByTypeId;
}
/**
 * Add the geometry of the geoboundaries provided to the geometries cache
 * @param geoboundaries Geoboundaries to update with geometry
 */
async function fetchNotCachedGeoboundariesGeometries(environment, geoboundaries, cacheGeoboundariesGeometries) {
    const geoboundariesFlat = Object.values(geoboundaries).flat();
    if (geoboundariesFlat.length === 0) {
        return [];
    }
    if (!isArrayOfGeoboundaryFront(geoboundariesFlat)) {
        throw new Error(`Colud not get geometries of all geoboundaries. Not complying expected structure. Compare content ${geoboundariesFlat} against GeoboundaryFront`);
    }
    const geoboundariesFlatTyped = geoboundariesFlat;
    const geometriesToRetrieve = geoboundariesFlatTyped.filter((geoboundary) => {
        const found = cacheGeoboundariesGeometries.find((cacheGeoboundaryGeometry) => cacheGeoboundaryGeometry.id === geoboundary.id &&
            cacheGeoboundaryGeometry.type_id === geoboundary.type_id);
        return geoboundary.has_geometry && found === undefined;
    });
    if (geometriesToRetrieve.length === 0) {
        return [];
    }
    const metadata = getAppMetadata();
    // Group geoboundaries by type id
    const geometriesGroupedByTypeId = geoboundariesGroupedByTypeId(geometriesToRetrieve);
    // Launch all queries by type id at the same time
    const promises = [];
    for (const geometriesToRetrieveIds of geometriesGroupedByTypeId) {
        promises.push(GeoboundaryService.getGeometryByIds(metadata, environment, geometriesToRetrieveIds));
    }
    const geometriesData = await Promise.all(promises);
    const geometriesDataFlat = geometriesData.flat() || [];
    return geometriesDataFlat;
}
export async function getRegionsFromCustomGeoboundariesDatasets(environment, filter) {
    const region15Values = [];
    const filterWithoutImportedZipCodes = [];
    const filterCount = filter.length;
    for (let i = 0; i < filterCount; i++) {
        const subfilter = filter[i];
        /* TODO: 365344 -
          Lo ideal sería evitar descargar nuevamente el contenido de los custom geoboundary datasets.
          Pero claro eso obliga a hacer una llamada al mutation a través de vuex, y eso no se debe hacer dentro de la función cacheCustomGeoboundaryDatasetsInFilter
          porque rompe el Share plan para la cloud function.
          Pero se puede hacer a continuación, si la función cacheCustomGeoboundaryDatasetsInFilter devuelve > 1 se hace guarda en el plan y sin problema.
    
          Falta revisar que cuando el plan se serialice no se tengan en cuenta los campos de cacheCustomGeoboundaryXxxx
        */
        await cacheCustomGeoboundaryDatasetsInFilter(environment, subfilter);
        if (subfilter.customGeoboundaryDatasetsCachedContent) {
            const customGeoboundaryDatasetsCachedContentCount = subfilter.customGeoboundaryDatasetsCachedContent.length;
            const customGeoboundaryDatasetsCachedContentCloned = [];
            for (let j = 0; j < customGeoboundaryDatasetsCachedContentCount; j++) {
                const dataset = subfilter.customGeoboundaryDatasetsCachedContent[j];
                if (dataset) {
                    const clonedGeoboundaries = {
                        ...dataset.filterForCustomGeoboundaryDatasetRegions,
                    };
                    delete clonedGeoboundaries.region_16;
                    delete clonedGeoboundaries.region_17;
                    delete clonedGeoboundaries.region_18;
                    if (clonedGeoboundaries.region_15) {
                        region15Values.push(clonedGeoboundaries.region_15);
                        delete clonedGeoboundaries.region_15;
                    }
                    customGeoboundaryDatasetsCachedContentCloned.push(clonedGeoboundaries);
                }
            }
            // Se crea un subfilter por cada uno de los elementos que se contengan en customGeoboundaryDatasetsCachedContentCloned
            const customGeoboundaryDatasetsCachedContentClonedCount = customGeoboundaryDatasetsCachedContentCloned.length;
            for (let j = 0; j < customGeoboundaryDatasetsCachedContentClonedCount; j++) {
                const geoboundaries = customGeoboundaryDatasetsCachedContentCloned[j];
                const clonedSubfilter = { ...subfilter };
                clonedSubfilter.geoboundaries = geoboundaries;
                filterWithoutImportedZipCodes.push(clonedSubfilter);
            }
        }
        if (subfilter.customExcludedGeoboundaryDatasetsCachedContent) {
            const customGeoboundaryDatasetsCachedContentCount = subfilter.customExcludedGeoboundaryDatasetsCachedContent.length;
            const customGeoboundaryDatasetsCachedContentCloned = [];
            for (let j = 0; j < customGeoboundaryDatasetsCachedContentCount; j++) {
                const dataset = subfilter.customExcludedGeoboundaryDatasetsCachedContent[j];
                if (dataset) {
                    const clonedGeoboundaries = {
                        ...dataset.filterForCustomGeoboundaryDatasetRegions,
                    };
                    delete clonedGeoboundaries.region_16;
                    delete clonedGeoboundaries.region_17;
                    delete clonedGeoboundaries.region_18;
                    if (clonedGeoboundaries.region_15) {
                        region15Values.push(clonedGeoboundaries.region_15);
                        delete clonedGeoboundaries.region_15;
                    }
                    customGeoboundaryDatasetsCachedContentCloned.push(clonedGeoboundaries);
                }
            }
            // Se crea un subfilter por cada uno de los elementos que se contengan en customGeoboundaryDatasetsCachedContentCloned
            const customGeoboundaryDatasetsCachedContentClonedCount = customGeoboundaryDatasetsCachedContentCloned.length;
            for (let j = 0; j < customGeoboundaryDatasetsCachedContentClonedCount; j++) {
                const geoboundaries = customGeoboundaryDatasetsCachedContentCloned[j];
                const clonedSubfilter = { ...subfilter };
                clonedSubfilter.geoboundaries = geoboundaries;
                filterWithoutImportedZipCodes.push(clonedSubfilter);
            }
        }
    }
    return {
        filter: filterWithoutImportedZipCodes,
        zipCodeRegions: region15Values,
    };
}
export async function retrieveGeoboundariesGeometryForLayer(environment, filters, cacheGeometries = []) {
    const region15Values = [];
    const region18Values = [];
    const filterWithoutImportedZipCodes = filters.map((subfilter) => {
        const clonedGeoboundaries = { ...subfilter.geoboundaries };
        delete clonedGeoboundaries.region_16;
        delete clonedGeoboundaries.region_17;
        if (clonedGeoboundaries.region_15) {
            region15Values.push(clonedGeoboundaries.region_15);
            delete clonedGeoboundaries.region_15;
        }
        if (clonedGeoboundaries.region_18) {
            region18Values.push(clonedGeoboundaries.region_18);
            delete clonedGeoboundaries.region_18;
        }
        return { ...subfilter, geoboundaries: clonedGeoboundaries };
    });
    if (isCustomGeoboundaryDatasetEnabled(getAppMetadata())) {
        const { filter: customGeoboundaryDatasetsAsFilters, zipCodeRegions: customGeoboundaryDatasetsZipRegions, } = await getRegionsFromCustomGeoboundariesDatasets(environment, filters);
        filterWithoutImportedZipCodes.push(...customGeoboundaryDatasetsAsFilters);
        region15Values.push(...customGeoboundaryDatasetsZipRegions);
    }
    const zipCodesGeometries = await getGeomertiesForZipCodes(environment, region15Values);
    const zipCodesGeometriesFormatted = zipCodesGeometries.map((element) => {
        return { geometry: element.geom };
    });
    const region18Geometries = await getGeometriesForRegion18(environment, region18Values);
    const region18GeometriesFormatted = region18Geometries.map((element) => {
        return { geometry: element };
    });
    const mergedGeoboundaries = mergeSubFiltersGeoboundaries(filterWithoutImportedZipCodes);
    const newGeoboundariesGeometries = await fetchNotCachedGeoboundariesGeometries(environment, mergedGeoboundaries, cacheGeometries);
    const availableGeometries = [
        ...cacheGeometries,
        ...newGeoboundariesGeometries,
    ];
    const geoboundaryLayerData = Object.values(mergedGeoboundaries).flat() || [];
    const geoboundaryLayerDataFormatted = [];
    geoboundaryLayerData.forEach((element) => {
        const geom = availableGeometries.find((cacheGeometry) => cacheGeometry.id === element.id &&
            cacheGeometry.type_id === element.type_id)?.geom;
        if (geom) {
            geoboundaryLayerDataFormatted.push({
                geometry: geom,
                properties: {
                    excluded: element.exclude,
                    id: element.id,
                    type_id: element.type_id,
                },
            });
        }
    });
    const customGeoboundaries = [];
    filters.forEach((subfilter) => {
        if (subfilter.customGeoboundaryDatasetsCachedContent) {
            subfilter.customGeoboundaryDatasetsCachedContent.forEach((dataset) => {
                if (dataset) {
                    const { filterForCustomGeoboundaryDatasetCustomGeoboundaries } = dataset;
                    filterForCustomGeoboundaryDatasetCustomGeoboundaries.features.forEach((feature) => {
                        customGeoboundaries.push({
                            geometry: feature.geometry,
                            properties: { excluded: false },
                        });
                    });
                }
            });
        }
        if (subfilter.customExcludedGeoboundaryDatasetsCachedContent) {
            subfilter.customExcludedGeoboundaryDatasetsCachedContent.forEach((dataset) => {
                if (dataset) {
                    const { filterForCustomGeoboundaryDatasetCustomGeoboundaries } = dataset;
                    filterForCustomGeoboundaryDatasetCustomGeoboundaries.features.forEach((feature) => {
                        customGeoboundaries.push({
                            geometry: feature.geometry,
                            properties: { excluded: true },
                        });
                    });
                }
            });
        }
    });
    // FILE CUSTOM GEOBOUNDARIES
    const customFileGeoboundaries = [];
    const customFileGeoboundariesPromises = [];
    filters.forEach((subfilter) => {
        if (subfilter.customGeoboundaryDatasetsCachedContent) {
            subfilter.customGeoboundaryDatasetsCachedContent.forEach((dataset) => {
                const region18 = dataset.filterForCustomGeoboundaryDatasetRegions?.region_18;
                if (region18) {
                    region18.forEach((dataset) => {
                        const datasetFileId = dataset.id.toString();
                        const datasetFileIds = [datasetFileId];
                        const geometriesPromise = GeometryService.getGeometriesCustomFileGeometries(getAppMetadata(), environment, datasetFileIds);
                        customFileGeoboundariesPromises.push(geometriesPromise);
                    });
                }
            });
        }
    });
    const allResults = await Promise.all(customFileGeoboundariesPromises);
    const fileCustomGeometries = allResults.flat();
    fileCustomGeometries.forEach((geometry) => {
        customFileGeoboundaries.push({
            geometry: geometry,
            properties: { excluded: false },
        });
    });
    // FILE CUSTOM GEOBOUNDARIES EXCLUDED
    const customFileGeoboundariesExcluded = [];
    const customFileGeoboundariesExcludedPromises = [];
    filters.forEach((subfilter) => {
        if (subfilter.customExcludedGeoboundaryDatasetsCachedContent) {
            subfilter.customExcludedGeoboundaryDatasetsCachedContent.forEach((dataset) => {
                const region18 = dataset.filterForCustomGeoboundaryDatasetRegions?.region_18;
                if (region18) {
                    region18.forEach((dataset) => {
                        // TODO: Replace by getGeometryForImportedFiles
                        const datasetFileId = dataset.id.toString();
                        const datasetFileIds = [datasetFileId];
                        const geometriesPromise = GeometryService.getGeometriesCustomFileGeometries(getAppMetadata(), environment, datasetFileIds);
                        customFileGeoboundariesExcludedPromises.push(geometriesPromise);
                    });
                }
            });
        }
    });
    const allResultsExcluded = await Promise.all(customFileGeoboundariesExcludedPromises);
    const fileCustomGeometriesExcluded = allResultsExcluded.flat();
    fileCustomGeometriesExcluded.forEach((geometry) => {
        customFileGeoboundariesExcluded.push({
            geometry: geometry,
            properties: { excluded: true },
        });
    });
    return [
        ...geoboundaryLayerDataFormatted,
        ...customGeoboundaries,
        ...zipCodesGeometriesFormatted,
        ...region18GeometriesFormatted,
        ...customFileGeoboundaries,
        ...customFileGeoboundariesExcluded,
    ];
}
export function getGeoboundariesIdsFilterIsochrones(filter) {
    const geoboundaries = { ...filter };
    delete geoboundaries.region_16;
    delete geoboundaries.region_17;
    delete geoboundaries.region_18;
    let allGeoboundariesWithGeom = true;
    const filterGeoboundaries = Object.entries(geoboundaries)
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        .reduce((acc, [key, value]) => {
        if (value.length) {
            // check if the geoboundary has geometry
            value.forEach((region) => {
                if (allGeoboundariesWithGeom) {
                    allGeoboundariesWithGeom = region.has_geometry;
                }
            });
            const Ids = value.map((x) => x.id);
            acc = [...acc, ...Ids];
        }
        return acc;
    }, [])
        .flat();
    return allGeoboundariesWithGeom ? filterGeoboundaries : [];
}
export function removeGeoboundaryCacheProperties(filter) {
    const filterWithoutCache = filter.map((subfilter) => {
        const geoboundaries = subfilter.geoboundaries;
        const keys = Object.keys(geoboundaries);
        const clonedAssetFilterGeoboundaries = {};
        keys.forEach((assetFilterGeoboundariesKey) => {
            const geoboundaries = subfilter.geoboundaries[assetFilterGeoboundariesKey];
            if (!geoboundaries) {
                throw new Error('Geoboundaries not found');
            }
            const clonedGeoboundaries = geoboundaries.map((element) => {
                if (element.geom) {
                    const clonedElement = { ...element };
                    clonedElement.geom = undefined;
                    return clonedElement;
                }
                return element;
            });
            clonedAssetFilterGeoboundaries[assetFilterGeoboundariesKey] = clonedGeoboundaries;
        });
        return {
            ...subfilter,
            geoboundaries: clonedAssetFilterGeoboundaries,
        };
    });
    return filterWithoutCache;
}
export async function getGeometryForImportedFiles(environment, filesToImport) {
    const geometriesPromises = filesToImport.map((file) => {
        const fileIds = [file.id.toString()];
        const geometriesPromise = GeometryService.getGeometriesCustomFileGeometries(getAppMetadata(), environment, fileIds);
        return geometriesPromise;
    });
    const allResults = await Promise.all(geometriesPromises);
    const allResultsFlattern = allResults.flat();
    return allResultsFlattern;
}
