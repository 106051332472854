import { Auth } from '@workspaces/types';
import ResolverBasic from './resolver-basic';
/**
 * 📢 3 different types of roles:
 *    - Admin
 *    - Power User
 *    - User
 */
class PermissionResolverEU extends ResolverBasic {
    canUploadCustomPOIs() {
        return true;
    }
    canEditAnyCustomPOIGroup() {
        const role = this.getRole();
        return role === Auth.Role.Admin;
    }
    permitComments() {
        return false;
    }
    canSavePlan(planOwner, planId, isPlanPublic) {
        if (!planId) {
            return true;
        }
        if (isPlanPublic && this.getRole() === Auth.Role.Admin) {
            return true;
        }
        if (this.getName() === planOwner) {
            return true;
        }
        return false;
    }
    canSavePlanAsPublic() {
        return true;
    }
    canDeletePlan(plan) {
        const planOwner = plan.created_by;
        const isPlanPublic = plan.is_public;
        if (isPlanPublic && this.getRole() === Auth.Role.Admin) {
            return true;
        }
        return this.getName() === planOwner;
    }
    canDeleteCustomGeoboundary(owner) {
        return this.isOwnerOrAdmin(owner);
    }
    canDeleteCustomPOIGroup(owner) {
        return this.isOwnerOrAdmin(owner);
    }
    canUpdateCustomPOIGroup(owner) {
        return this.isOwnerOrAdmin(owner);
    }
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    canEditPlanPrice(plan) {
        return false;
    }
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    canBookPlanPackage(plan) {
        return false;
    }
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    canConfirmBookingPlanPackageByAdmin(plan) {
        return false;
    }
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    canConfirmBookingPlanPackageByAgency(plan) {
        return false;
    }
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    canFinalizeConfirmBookingPlanPackageByAdmin(plan) {
        return false;
    }
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    canCancelPlanPackageByAdmin(plan) {
        return false;
    }
    canShowWidgetUploadAssets() {
        return true;
    }
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    canEditPlanPackageDates(plan) {
        return false;
    }
}
export default PermissionResolverEU;
