const opacityForSelectedQuintilesFill = 200;
export const QuintileColors = [
    new Uint8Array([255, 160, 160, opacityForSelectedQuintilesFill]),
    new Uint8Array([255, 216, 176, opacityForSelectedQuintilesFill]),
    new Uint8Array([255, 255, 176, opacityForSelectedQuintilesFill]),
    new Uint8Array([224, 255, 176, opacityForSelectedQuintilesFill]),
    new Uint8Array([240, 245, 255, opacityForSelectedQuintilesFill]),
    new Uint8Array([255, 255, 255, opacityForSelectedQuintilesFill]), // Unselected background: rgba(255, 255, 255) rgba(175, 175, 175);
];
const opacityForSelectedQuintilesLine = 255;
const opacityForUnsalectedQuintilesLine = 102;
export const QuintileLineColors = [
    new Uint8Array([255, 120, 120, opacityForSelectedQuintilesLine]),
    new Uint8Array([255, 203, 157, opacityForSelectedQuintilesLine]),
    new Uint8Array([255, 247, 125, opacityForSelectedQuintilesLine]),
    new Uint8Array([205, 255, 151, opacityForSelectedQuintilesLine]),
    new Uint8Array([210, 222, 255, opacityForSelectedQuintilesLine]),
    new Uint8Array([175, 175, 175, opacityForUnsalectedQuintilesLine]), // Unselected background: rgba(175, 175, 175);
];
export const TransparentColor = new Uint8Array([0, 0, 0, 0]);
export const LineColors = {
    selected: new Uint8Array([242, 111, 33, 255]),
    unSelected: new Uint8Array([255, 255, 255, 255]),
};
