import { GETTERS } from './messages.constants';
export const getters = {
    [GETTERS.GET_SHOW]: (state) => {
        return state.show;
    },
    [GETTERS.GET_TITLE]: (state) => {
        return state.title;
    },
    [GETTERS.GET_TYPE_OF_MESSAGE]: (state) => {
        return state.typeOfMessage;
    },
    [GETTERS.GET_MESSAGE]: (state) => {
        return state.msg;
    },
};
