import { AssetsService } from '@workspaces/services';
export default {
    async getAssetsToShowBasedOnAudienceFilter(meta, environment, queryParams) {
        const data = await AssetsService.getAssetsToShowBasedOnAudienceFilter(meta, environment, queryParams);
        return data;
    },
    async getAssetsToShowBasedOnAudienceFilterV2(meta, environment, queryParams) {
        const data = await AssetsService.getAssetsToShowBasedOnAudienceFilterV2(meta, environment, queryParams);
        return data;
    },
    async getAssetsTypeL1DigitalFilter(meta, environment, queryParams) {
        const data = await AssetsService.getAssetsTypeL1DigitalFilter(meta, environment, queryParams);
        return data;
    },
    async getAssetsTypeL1StaticFilter(meta, environment, queryParams) {
        const data = await AssetsService.getAssetsTypeL1StaticFilter(meta, environment, queryParams);
        return data;
    },
    async getAssetsTypeL1(meta, environment, queryParams) {
        const data = await AssetsService.getAssetsTypeL1(meta, environment, queryParams);
        return data;
    },
    async getAssetsTypeL2(meta, environment, queryParams) {
        const data = await AssetsService.getAssetsTypeL2(meta, environment, queryParams);
        return data;
    },
    async getAssetsTypeL3(meta, environment, queryParams) {
        const data = await AssetsService.getAssetsTypeL3(meta, environment, queryParams);
        return data;
    },
    async getAssetsTypeL4(meta, environment, queryParams) {
        const data = await AssetsService.getAssetsTypeL4(meta, environment, queryParams);
        return data;
    },
    async getAssetsTypeL5(meta, environment, queryParams) {
        const data = await AssetsService.getAssetsTypeL5(meta, environment, queryParams);
        return data;
    },
    async getAssetsTypeL6(meta, environment, queryParams) {
        const data = await AssetsService.getAssetsTypeL6(meta, environment, queryParams);
        return data;
    },
    async getAssetsTypeL7(meta, environment, queryParams) {
        const data = await AssetsService.getAssetsTypeL7(meta, environment, queryParams);
        return data;
    },
    async getAssetsTypeL8(meta, environment, queryParams) {
        const data = await AssetsService.getAssetsTypeL8(meta, environment, queryParams);
        return data;
    },
    async getAssetsTypeL9(meta, environment, queryParams) {
        const data = await AssetsService.getAssetsTypeL9(meta, environment, queryParams);
        return data;
    },
    async getAssetById(meta, environment, id) {
        const data = await AssetsService.getAssetById(meta, environment, id);
        return data;
    },
    async getAssetsByMinimumDistance(meta, environment, ids, distance) {
        const data = await AssetsService.getAssetsByMinimumDistance(meta, environment, ids, distance);
        return data;
    },
    async getAidaAssetsFromFile(meta, environment, fileName) {
        const data = await AssetsService.getAidaAssetsFromFile(meta, environment, fileName);
        return data;
    },
};
