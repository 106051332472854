import { Environment, Carto } from '@workspaces/types';
import { CartoMetricEventService } from '@workspaces/services';
import { getISO2Flavour } from '@/plan/metadata/metadata.helper';
import { getInstance as getAuth0ServiceIntance } from '@/auth/index';
async function sendCartoMetricEvent(meta, environment, eventType) {
    if (environment.isTesting()) {
        return;
    }
    if (environment.getEnvironment() !== Environment.Environment.Production) {
        return;
    }
    const auth0Instance = getAuth0ServiceIntance();
    const userId = auth0Instance.getCartoUser();
    const accountId = auth0Instance.getAccountId();
    const flavour = getISO2Flavour(meta);
    let eventName = eventType.toString();
    eventName = eventName.replace(Carto.METRIC_EVENT_PATTERN_TO_REPLACE, flavour);
    const event = {
        event_type: eventName,
        timestamp: new Date().toISOString(),
        payload: {
            id: accountId,
            user_id: userId,
            template: eventName,
            metadata_org: `Radarview${flavour}`,
        },
    };
    await CartoMetricEventService.sendCartoMetricEvent(meta, environment, event);
}
export default {
    async sendLoginEvent(meta, environment) {
        await sendCartoMetricEvent(meta, environment, Carto.MetricEventType.Login);
    },
    async sendSolutionBusinessValueEvent(meta, environment) {
        await sendCartoMetricEvent(meta, environment, Carto.MetricEventType.SolutionBusinessValue);
    },
};
