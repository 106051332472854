import { AUTHO_CARTO_TOKEN } from '@/api/authService';
const testingValuePattern = 'testing';
const localStorageKeyForCarto3ApiToken = `${testingValuePattern}_carto3_api_key`;
export function getCarto3ApiTokenFromLocalStorage(environment) {
    const token = localStorage.getItem(localStorageKeyForCarto3ApiToken);
    const isTesting = environment.isTesting();
    return isTesting ? token : null;
}
export function getTestingValuesFromLocalStorage() {
    let token = {
        key: '',
        value: '',
    };
    let apiKey = {
        key: '',
        value: '',
    };
    const numberOfLocalStorageKeys = localStorage.length;
    for (let i = 0; i < numberOfLocalStorageKeys; i++) {
        const key = localStorage.key(i);
        if (key && key.includes(testingValuePattern)) {
            const content = localStorage.getItem(key);
            const value = JSON.parse(content || '{}');
            if (key.startsWith(AUTHO_CARTO_TOKEN)) {
                token = { key, value };
            }
            else if (key === localStorageKeyForCarto3ApiToken) {
                apiKey = { key, value };
            }
            else {
                console.error('Unexpected testing key in localStorage');
                return null;
            }
        }
    }
    const result = token.key && apiKey.key ? { token, apiKey } : null;
    console.debug('😈 Testing: getTestingValuesFromLocalStorage', result);
    return result;
}
export function setTestingInfoInLocalStorage(values) {
    localStorage.setItem(values.token.key, values.token.value);
    localStorage.setItem(values.apiKey.key, values.apiKey.value);
}
