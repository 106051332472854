export var DELIVERY_HUMAN_REPRESENTATION;
(function (DELIVERY_HUMAN_REPRESENTATION) {
    DELIVERY_HUMAN_REPRESENTATION[DELIVERY_HUMAN_REPRESENTATION["Month"] = 0] = "Month";
    DELIVERY_HUMAN_REPRESENTATION[DELIVERY_HUMAN_REPRESENTATION["Week"] = 1] = "Week";
})(DELIVERY_HUMAN_REPRESENTATION || (DELIVERY_HUMAN_REPRESENTATION = {}));
export var TYPES_OPTIMIZE_DISTRIBUTION;
(function (TYPES_OPTIMIZE_DISTRIBUTION) {
    TYPES_OPTIMIZE_DISTRIBUTION["Impressions"] = "impressions";
    TYPES_OPTIMIZE_DISTRIBUTION["Audience"] = "audience";
})(TYPES_OPTIMIZE_DISTRIBUTION || (TYPES_OPTIMIZE_DISTRIBUTION = {}));
export var DataModelFieldTypes;
(function (DataModelFieldTypes) {
    DataModelFieldTypes["String"] = "string";
    DataModelFieldTypes["Number"] = "number";
    DataModelFieldTypes["Boolean"] = "boolean";
    DataModelFieldTypes["Date"] = "date";
    DataModelFieldTypes["Array"] = "array";
    DataModelFieldTypes["Geography"] = "geography";
    DataModelFieldTypes["ArrayNumbers"] = "array_numbers";
    DataModelFieldTypes["ArrayStrings"] = "array_strings";
})(DataModelFieldTypes || (DataModelFieldTypes = {}));
export var RemoteFunction;
(function (RemoteFunction) {
    RemoteFunction["FirebaseToken"] = "firebase_token";
    RemoteFunction["Audit"] = "audit";
    RemoteFunction["BigQueryCustomPOIS"] = "big_query_custom_POIS";
    RemoteFunction["Aida"] = "aida";
    RemoteFunction["SharedPlan"] = "share_plan";
    RemoteFunction["Isochrones"] = "isochrones";
    RemoteFunction["Auth0"] = "auth0";
    RemoteFunction["CustomGeoboundaries"] = "custom_geoboundaries";
    RemoteFunction["SignedUrls"] = "signed_urls";
    RemoteFunction["OptimizeDistribution"] = "optimize_distribution";
    RemoteFunction["Geocoding"] = "geocoding";
})(RemoteFunction || (RemoteFunction = {}));
