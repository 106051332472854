export var Action;
(function (Action) {
    Action["Share"] = "share";
    Action["Assets"] = "load_assets";
    Action["AssetsPagination"] = "load_assets_pagination";
    Action["Plan"] = "load_plan";
    Action["Geoboundary"] = "geoboundary_geometries";
    Action["CustomPOIs"] = "custom_POIs";
    Action["Asset"] = "asset_details";
    Action["Insights"] = "insights";
    Action["PasswordRequired"] = "password_required";
    Action["SharedExportPoisAssets"] = "shared_export_pois_assets";
    Action["SharedExportAllPois"] = "shared_export_all_pois";
    Action["SharedExportAudiencesAssets"] = "shared_export_audiences_assets";
    Action["SharedExportAssetsPOIsDistance"] = "shared_export_assets_pois_distance";
    Action["ExistCustomPOIsGroups"] = "exist_custom_pois_groups";
    Action["Isochrones"] = "isochrones";
})(Action || (Action = {}));
