export default class ErrorMessage extends Error {
    localeTitleKey;
    localeMessageKey;
    error;
    constructor(localeTitleKey, localeMessageKey, message, error) {
        super(message);
        this.localeTitleKey = localeTitleKey;
        this.localeMessageKey = localeMessageKey;
        this.error = error;
    }
}
